.enerjoyApp .page_home .appContent {
    box-shadow: none;
    background: transparent;
}

.page_home .homeTopRow {
    overflow: hidden;
    margin: 0;
}

.page_home .homeTopRow > div {
    width: 70.9%;
    max-width: 70.9%;
    flex-basis: 70.9%;
}

.mob.page_home .homeTopRow > div {
    width: 100%;
    flex-basis: 100%;
    margin: 0;
    max-width: none;
    flex-direction: column;
    padding: 0 15px;
}

.page_home .homeTopRow > div:first-child {
    min-height: 400px;
    max-width: 366px;
    border-right: 1px solid #dde1e7;
}

.mob.page_home .homeTopRow > div:first-child {
    min-height: auto;
    max-width: none;
    border-right: none;
    padding: 0
}

.home-humility {
    text-align: center;
}

.home-fa-twitter {
    margin-left: 2em;
}

.homepage .homeTopRow {
    display: flex;
}

.mob .homepage .homeTopRow {
    flex-direction: column-reverse;
}

.page_home .homeMainArea {
    margin-top: 20px;
    display: flex;
}

.page_home .homeMainArea > div:nth-child(2) {
    padding: 0 17px;
    margin-left: 29px;
    background-color: #FFFFFF;
    width: 387px;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.05);
    padding-bottom: 22px;
}

.page_home .empty-feed {
    text-align: center;
    color: #662D91;
    padding-bottom: 50px;
}

.page_home .empty-feed:before {
    content: '';
    width: 150px;
    border-bottom: 2px solid #B396C8;
    display: block;
    margin: 0 auto;
}

.page_home .empty-feed .main-title {
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 14px;
}

.page_home .empty-feed .sub-title {
    font-size: 16px;
    margin-bottom: 34px;
}

.page_home .empty-feed .yogaIcon {
    height: 165px;
    margin-bottom: 30px;
}

.page_home .empty-feed .create-challenge-btn {
    background-color: #6724C6;
    border-radius: 25px;
    color: #FFFFFF;
    height: 38px;
    width: 220px;
    font-weight: bold;
    text-transform: unset;
}

.mob .metrics-summary-widget {
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.metrics-summary-widget .header-line .metrics-filter-container {
    position: relative;
    width: 200px;
    z-index: 3;
}
.mob .metrics-summary-widget .header-line .metrics-filter-container {
    width: 100%;
}

.metrics-summary-widget .header-line .metrics-filter-container .arrow-down {
    position: absolute;
    color: white;
    right: 10px;
    z-index: 1;
    top: 0;
    bottom: 50%;
    transform: translateY(25%);
}

.metrics-summary-widget .header-line .metrics-filter {
    width: 100%;
    border-radius: 30px;
    background-color: #6724C6;
    height: 40px;
    color: white;
    font-size: 16px;
    text-transform: capitalize;
}

.metrics-summary-widget .header-line .menu[style] {
    right: 0;
    width: 100%;
    justify-content: center;
    display: flex;
    z-index: 100;
}

.metrics-summary-widget .header-line .userProfileMenu {
    flex: 1 1;
    text-align: center;
    text-transform: capitalize;
}

.mob .metrics-summary-widget .metrics {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
}

.metrics-summary-widget .metricCol {
}

.metrics-summary-widget .metricCol:nth-child(3n + 2) {
    margin: 0 18px;
}

.metrics-summary-widget .metricCol:nth-child(n + 4) {
    margin-top: 15px;
}

.mob .metrics-summary-widget .metricCol, .mob .metrics-summary-widget .metricCol:nth-child(3n + 2), .mob .metrics-summary-widget .metricCol:nth-child(n + 4) {
    -webkit-flex: auto;
    flex: auto;
    margin: 0px 0 5px 0;
}

.metrics-summary-widget .metricBox {
    height: 137px;
    border-radius: 6px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.17);
    color: #FFF;
    padding: 5px 9px;
    position: relative;
}

.metrics-summary-widget .metricBox .popOverLink {
    text-decoration: none;
}

.metrics-summary-widget .metricBox .popOver {
    color: #2a1566;
}

.mob .metrics-summary-widget .metricBox {
    height: auto;
    padding: 3px 10px 5px;
    position: relative;
}

.mob .metrics-summary-widget .metrics > .metricCol:nth-child(n) .metricBox {
    background-color: #249BC9;
}

.mob .metrics-summary-widget .metrics > .metricCol:nth-child(2n) .metricBox {
    background-color: #4CD57C;
}

.metrics-summary-widget .metricTitle {
    font-size: 19px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 50px;
}

.metrics-summary-widget .drillDownIcons {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    font-size: 0;
    padding: 0 3px;
}

.metrics-summary-widget .drillDownIcons button {
    color: #FFF;
    padding: 4px;
    font-size: 16px;
}

.metrics-summary-widget .drillDownIcons button:first-child {
    border-right: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 0;
}

.metrics-summary-widget .drillDownIcons button svg {
    font-size: 1em;
}


.metrics-summary-widget .metricPerformance {
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 29px;
    padding-top: 6px;
}

.metrics-summary-widget .metricPerformance .leads {
    font-size: 35px;
}

.mob .metrics-summary-widget .metricPerformanceArea {
    flex: 1 1;
}

.mob .metrics-summary-widget .metricPerformance {
    padding-top: 0;
    line-height: 24px;
    padding-bottom: 0;
}

.mob .metrics-summary-widget .metricPerformance .leads {
    font-size: 24px;
}