.summary-widget-modal .postHeader {
    padding: 0 0 0 20px;
    display: flex;
    align-items: center;
}

.summary-widget-modal .pointsModal {
    width: 900px;
}

.mob .summary-widget-modal .pointsModal {
    width: auto;
}

.summary-widget-modal .postBody.coinsModal {
    width: 360px;
}

.mob .summary-widget-modal .postBody.coinsModal {
    width: auto;
}

.summary-widget-modal .feedbackSummaryModalBody, .summary-widget-modal .checkInsSummaryModalBody {
    width: 540px;
}

.summary-widget-modal .feedbackSummaryModalBody.singleColumn, .summary-widget-modal .checkInsSummaryModalBody.singleColumn {
    width: 360px;
}

.summary-widget-modal, .summary-widget-modal .post {
    height: 100%;
    margin-top: 0;
}

.summary-widget-modal .postBody {
    height: 100%;
    background: #FFF;
    padding: 0;
    overflow: auto;
    box-shadow: 0 -39px 20px rgba(0, 0, 0, 0.15);
}

.summary-widget-modal .postBody .scroll {
    display: flex;
    min-height: 100%;
}

.summary-widget-modal .postHeaderIcon {
    position: absolute;
    top: -6px;
    left: -40px;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 17px 5px rgba(94, 63, 186, 0.09);
    z-index: 2;
}

.summary-widget-modal .postHeaderIcon.commissionIcon {
    font-size: 32px;
    color: #6724c6;
    text-shadow: 0 0 3px #36e08f;
}

.summary-widget-modal .postHeaderIcon .pointsIcon {
    width: 34px;
    height: 34px;
}

.summary-widget-modal .postHeaderIcon .coinsIcon, .summary-widget-modal .postHeaderIcon .clapIcon {
    width: 37px;
    height: 37px;
}

.summary-widget-modal .postHeaderIcon .commissionsIcon {
    fill: rgb(123, 39, 218);
    width: 1.3em;
    height: 1.3em;
}

.summary-widget-modal .postHeaderIcon .checkInIcon {
    width: 37px;
    height: 37px;
    font-size: 18px;
}

.summary-widget-modal .postHeaderTitle {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 15px;
    width: 100%;
    margin-top: 9px;
    padding-bottom: 5px;
}

.summary-widget-modal .post-title {
    color: #FFF;
    font-weight: bold;
    font-size: 18px;
    text-transform: capitalize;
}

.summary-widget-modal .pointsTable {
    flex: 400px 0 0;
    transition: height 0.4s;
    overflow: hidden;
}

.summary-widget-modal .summaryTable {
    transition: height 0.4s;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.summary-widget-modal .summaryTable.closed {
    height: 250px;
}

.summary-widget-modal .summaryTable.open {
    height: auto;
}

.summary-widget-modal .summaryHeader {
    min-height: 46px;
    background: #6724C6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 28px;
}

.summary-widget-modal .summaryHeader .title {
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
    color: #FFFFFF;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.summary-widget-modal .commissionModal .summaryHeader {
    justify-content: normal;
    padding: 0;
    position: relative;
}

.summary-widget-modal .commissionModal .summaryHeader > .title, .summary-widget-modal .commissionModal .summaryCol {
    width: 175px;
    flex: 175px 0 0;
    align-items: center;
    border-left: 1px solid hsl(265deg 69% 76%);
    padding: 0;
    height: 100%;
    justify-content: center;
    position: relative;
}

.summary-widget-modal .commissionModal .summaryHeader .title .primary {
    text-transform: none;
    border: 0;
    font-size: 15px;
}

.summary-widget-modal .postBody.commissionsModal, .summary-widget-modal .postBody.commissionDetailsModal {
    width: 360px;
}

.summary-widget-modal .commissionModal .summaryCol .userRow {
    padding: 0 10px;
}

.summary-widget-modal .commissionModal .summaryCol .userRow.total {
    border-top: 1px solid hsl(265deg 69% 76%);
    background: hsl(265deg 69% 95%);
    font-weight: bold;
}

.summary-widget-modal .commissionModal .userRow .details {
    width: 100%;
    padding: 0;
}

.summary-widget-modal .commissionModal .unlinked {
    color: #33363b;
    opacity: 0.9;
}

.summary-widget-modal .commissionModal .unlinked svg {
    font-size: 1.4em;
    position: relative;
    top: 4px;
    right: -5px;
    color: rgb(255 0 0 / 60%);
}

.summary-widget-modal .commissionModal .summaryCol:first-child .userRow .details {
    width: calc(100% - 37px);
}

.summary-widget-modal .commissionModal .summaryCol .details .value {
    text-align: center;
    width: 100%;
}

.summary-widget-modal .commissionModal .summaryCol .userRow.total .name,
.summary-widget-modal .commissionModal .summaryCol .userRow.total .value {
    font-family: "OpenSans", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
}

.summary-widget-modal .commissionModal .summaryHeader > .title:first-child, .summary-widget-modal .commissionModal .summaryCol:first-child {
    align-items: flex-start;
}

.summary-widget-modal .commissionModal .summaryHeader > .title:first-child {
    padding-left: 20px;
}


.summary-widget-modal .summaryHeader .title .primary {
    border-bottom: 1px solid;
}

.summary-widget-modal .summaryHeader .title .secondary {
    font-size: 11px;
    padding: 2px 0;
}

.summary-widget-modal .pointsModal .summaryHeader {
    padding: 0;
}

.summary-widget-modal .pointsModal .summaryHeader .title:nth-child(1) {
    flex: 25% 0 0;
}

.mob .summary-widget-modal .pointsModal .summaryHeader .title:nth-child(1) {
    flex: 1;
}

.summary-widget-modal .pointsModal .summaryHeader .title:nth-child(2) {
    flex: 20% 0 0;
}

.mob .summary-widget-modal .pointsModal .summaryHeader .title:nth-child(2) {
    flex: 1;
}

.summary-widget-modal .pointsModal .summaryHeader .title:nth-child(2n + 3) {
    flex: 15% 0 0;
}

.mob .summary-widget-modal .pointsModal .summaryHeader .title:nth-child(2n + 3) {
    flex: 1;
}

.summary-widget-modal .pointsModal .summaryHeader .title:nth-child(4) {
    flex: 25% 0 0;
}

.summary-widget-modal .pointsModal .summaryCol:first-child {
    flex: 45% 0 0;
}

.summary-widget-modal .pointsModal .summaryCol:last-child {
    background-color: #f6f0ff;
    flex: 55% 0 0;
}

.mob .summary-widget-modal .pointsModal .summaryCol:first-child {
    flex: 1;
}

.mob .summaryCol .arrow-down {
    position: absolute;
    right: 5px;
    color: #6724C6;
}

.mob .summary-widget-modal .pointsModal .summaryCol:last-child {
    background: none;
    flex: 1;
    padding-left: 0;
}

.mob .summaryBody .summaryCol .userActivities {
    width: 100%;
    background-color: #f6f0ff;
}

.summary-widget-modal .summaryBody a {
    text-decoration: none;
}

.summary-widget-modal .summaryBody .disabled {
    pointer-events: none;
}

.summary-widget-modal .summaryBody a:hover {
    background-color: #efe4ff;
}

.summary-widget-modal .summaryBody .userRow.selected:not(:hover) {
    background-color: #f6f0ff;
}

.mob .summary-widget-modal .summaryBody .userRow.selected:hover, .mob .summary-widget-modal .summaryBody .userRow.selected:not(:hover) {
    background-color: #f9f5ff;
}

.summary-widget-modal .summaryBody {
    display: flex;
    flex: 100% 1 1;
    min-height: 100%;
}

.summary-widget-modal .summaryCol {
    flex: 1 1;
    display: flex;
    padding-left: 5px;
    flex-direction: column;
    align-items: center;
}

.summary-widget-modal .userRow {
    display: flex;
    padding: 0 30px 0 12px;
    min-height: 48px;
    align-items: center;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
}

.mob .summary-widget-modal .post_periodic_team_points_update .userRow {
    flex-direction: row;
}

.mob .summary-widget-modal .post_periodic_team_points_update .userRow.pointGrant {
    padding-right: 5px;
    padding-left: 5px;
}

.summary-widget-modal .post_periodic_team_points_update .pos {
    margin-right: 15px;
    font-size: 13px;
    color: #39257A;
}

.summary-widget-modal .dotted {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    align-items: center;
}

.mob .summary-widget-modal .post_periodic_team_points_update .dotted {
    flex: 1 1;
}

.summary-widget-modal .selected {
    border-bottom: 0;
}

.summary-widget-modal .userRow .details {
    display: flex;
    align-items: center;
    padding: 5px;
    text-transform: capitalize;
}

.summary-widget-modal .userRow.pointGrant .details {
    text-align: center;
}

.summary-widget-modal .coinsModal .userRow .details {
    flex-direction: column;
    align-items: flex-start;
    flex: 1 1;
}

.summary-widget-modal .commissionsModal .userRow .details {
    width: 100%;
}

.summary-widget-modal .commissionsModal .userRow .details > .value {
    flex: 70%;
}

.summary-widget-modal .commissionsModal .userRow .details > .name {
    flex: 30%;
    text-align: right;
}

.summary-widget-modal button.export-to-excel {
    white-space: nowrap;
    background: transparent;
    border: 0;
    color: #38ed96;
    font-size: 15px;
    padding-top: 10px;
    font-weight: bold;
    text-shadow: 0 0 5px black;
}

.summary-widget-modal button.export-to-excel:hover {
    text-decoration: underline;
}

.summary-widget-modal .userRow .name {
    color: #39257A;
}

.summary-widget-modal .userRow .value {
    color: #6A5E91;
}

.summary-widget-modal .post_periodic_team_points_update .userRow .value.coins {
    display: flex;
    align-items: center;
    flex: 70px 0 0;
    justify-content: space-between;
}

.summary-widget-modal .post_periodic_team_points_update .userRow .value.coins svg {
    width: 25px;
    height: 25px;
    margin-right: 5px;
}

.summary-widget-modal .post_periodic_team_points_update .userRow .multi-rows {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.summary-widget-modal .post_periodic_team_points_update .userRow .multi-rows .percentages {
    font-size: 10px;
}

.summary-widget-modal .post_periodic_team_points_update img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 10px;
}

.summary-widget-modal .coinsModal .summaryCol:first-child .userRow {
    padding-right: 12px;
}

.summary-widget-modal .summaryCol .userRow {
    display: flex;
    padding: 7px 30px 7px 12px;
    min-height: 55px;
    width: 100%;
    border-bottom: 1px dotted #D9D6E0;
    position: relative;
}

.summary-widget-modal .summaryCol .userRow > a {
    display: flex;
    width: 100%;
}

.summary-widget-modal .summaryCol .userRow .userImg {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    margin-right: 9px;
    flex: 37px 0 0;
}

.summary-widget-modal .summaryCol .userRow .name {
    font-size: 14px;
    color: #39257A;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.mob .summary-widget-modal .summaryCol .userRow .name {
    white-space: normal;
}

.summary-widget-modal .summaryCol .userRow .value {
    font-size: 13px;
    color: #7B43CB;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
}

.summary-widget-modal .summaryCol .userRow .value.coins {
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    padding-left: 20px;
}

.mob .summary-widget-modal .summaryCol .userRow .value.coins {
    flex: 33% 0 0;
    justify-content: left;
}

.mob .summary-widget-modal .summaryCol .userRow.pointGrant .value.coins {
    flex: 25% 0;
    flex-shrink: 0;
}

.mob .summary-widget-modal .summaryCol .userRow .userActivities .value.coins {
    margin-left: 15px;
}

.summary-widget-modal .summaryCol .userRow .no_received_coins_reason {
    position: absolute;
    top: 30px;
    right: 25px;
    color: #e53838;
    font-size: inherit;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    border: 0;
}

.enerjoyApp .no_received_coins_reasonTooltip {
    width: 250px;
    font-size: 13px;
    line-height: 16px;
    position: relative;
    top: 5px;
    padding: 5px 15px;
}

.enerjoyApp :not(.menu) .no_received_coins_reasonTooltip > div {
    text-align: left;
}

.enerjoyApp .no_received_coins_reasonTooltip .no_received_coins_reason_title {
    font-size: 14px;
    padding-bottom: 1px;
    margin: 0 0 2px 0;
    border-bottom: 1px dotted #D9D6E0;
}

.enerjoyApp :not(.menu) .no_received_coins_reasonTooltip > div.conditionRow {
    font-size: 13px;
}

.enerjoyApp .no_received_coins_reasonTooltip .conditionRow:before {
    content: '- ';
}

.summary-widget-modal .summaryCol .pos {
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 13px;
    color: #414b6b;
    width: 17px;
    padding-top: 7px;
}

.mob .summary-widget-modal .summaryCol .pos {
    flex: 17px 0 0;
    width: auto;
}

.summary-widget-modal .open-summary-table-link-container {
    margin-top: 8px;
}

.summaryModal .modalContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 80%;
    outline: none;
}

.summaryModal .mobileModal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    transform: none;
}

.summaryModal .modalContent .pageLoadProgressCircle {
    text-align: center;
    position: relative;
    top: 100px;
    color: #FFF;
}

.summaryModal .modalContent .pageLoadProgressCircle svg {
    height: 100%;
    width: 100%;
}

.summary-widget-modal .feedbackSummaryModalBody .summaryCol:last-child, .summary-widget-modal .checkInsSummaryModalBody .summaryCol:last-child {
    background-color: #f6f0ff;
}

.commissionDetailsModal .summaryRow {
    height: 35px;
    font-size: 16px;
    align-items: center;
    display: flex;
    border-bottom: 1px solid hsl(265deg 69% 76%);
    width: 100%;
    padding-left: 20px;
}

.commissionDetailsModal .verticalHeader .summaryRow {
    background: hsl(268deg 71% 50%);
    color: #FFF;
}

.commissionDetailsModal .verticalHeader .componentLabel {
    justify-content: inherit;
}

.commissionDetailsModal .summaryCol.verticalHeader {
    flex: 190px 0 0;
}