.erj-spinner input {
    margin: 5px 0;
    text-align: center;
    padding: 0;
}

.erj-number-input {
    position: relative;
}

.erj-spinner .arrow-up,
.erj-number-input .arrow-up {
    cursor: pointer;
    height: 10px;
    background: url("../../assets/arrow_up.png") no-repeat center;
}

.erj-spinner .arrow-down,
.erj-number-input .arrow-down {
    cursor: pointer;
    height: 10px;
    background: url("../../assets/arrow_down.png") no-repeat center;
}

.erj-number-input {
    display: flex;
    align-items: center;
    justify-content: center;
}

.erj-number-input input {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    text-align: center;
    padding: 0;
}

.erj-number-input .arrows {
    position: absolute;
    width: 10px;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}

.ErjConfirm .ErjConfirm-title {
    text-transform: uppercase;
}

.ErjConfirm.prompt .ErjConfirm-buttons button {
    margin: 0 10px;
}

.ErjConfirm.prompt .cancelBtn {
    color: inherit;
    border-color: hsla(0, 77%, 56%, 0.75);
}

.ErjConfirm.prompt .confirmBtn {
    background: #9824c6;
    color: #FFF;
}

.avatarCropper {
    position: relative;
    outline: 0;
    height: 196px;
    width: 196px;
    border: 1px solid #dddbdb;
    cursor: pointer;
}

.avatarCropper input[type="file"] {
    position: absolute;
    z-index: -1;
}

.avatarCropper input[type="range"] {
    padding-left: 0;
    width: 100%;
}

.avatarCropper .avatar-cropper-block {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    line-height: 0;
}

.avatarCropper .imagePlaceholder {
    color: #e8e8e8;
    font-size: 135px;
    position: relative;
    top: 24px;
    left: 20px;
}

.avatarCropper .userProfileImg {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.avatarCropper .pageLoadProgressCircle {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}

.erjMenu {
    margin-top: 15px;
}

.erjMenu:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 2px;
    right: -7px;
    box-sizing: border-box;
    border: 6px solid black;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: #ffffff;
    border-bottom-color: #ffffff;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -3px 3px 3px -2.4px rgba(0, 0, 0, 0.33);
}

.enerjoyApp .erjMenu-button {
    cursor: pointer;
    padding: 0;
    min-width: auto;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    text-transform: inherit;
    color: inherit;
}

.erjMenu-container ul li {
    font-size: 14px;
    color: #494c50;
}

.erjMenu-container > div {
    top: -5px;
    left: -13px;
    z-index: 1;
}

.dropdownIcon-erjMenu-menu {
    font-size: 18px;
    margin-left: 3px;
    vertical-align: middle;
}

.emojiPopOver .emojiPopBody {
    height: 48px;
    border: 1px solid #dbe1e7;
    overflow: hidden;
    box-shadow: -1px 3px 8px 0px rgba(0, 0, 0, 0.14);
    border-radius: 48px;
}

.emojiPopOver .chooseThisEmojiBtn {
    color: inherit;
    width: 46px;
    height: 46px;
    outline: none;
    padding: 0;
}

.popOverLinkContainer {
    display: inline-block;
}

.enerjoyApp .popover-target {
    padding: 0;
    min-width: auto;
    line-height: inherit;
    font-weight: inherit;
    font-family: inherit;
    border-radius: inherit;
    color: inherit;
    text-transform: inherit;
}

.enerjoyApp .popover-target:hover {
    background: inherit;
}

.popOver {
    z-index: 10;
}

.popOver .popBody {
    position: relative;
    top: 10px;
    background: #FFF;
    padding: 10px;
    box-shadow: -1px 1px 6px 0 rgba(0, 0, 0, 0.33);
    overflow: visible;
    text-align: left;
}

.popOver .popBody.right {
    top: auto;
    left: 10px;
}

.popOver[x-placement="top-start"] .popBody, .popOver[x-placement="top"] .popBody, .popOver[x-placement="top-end"] .popBody {
    top: -10px;
}

.popOver .popBody:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 1px;
    left: 30%;
    box-sizing: border-box;
    border: 5px solid;
    border-color: transparent transparent #FFF #FFF;
    transform-origin: 0 0;
    transform: translateX(50%) rotate(135deg);
    box-shadow: -1px 1px 2px 0 rgba(0, 0, 0, 0.16);
}

.popOver[x-placement="top-start"] .popBody:before, .popOver[x-placement="top-end"] .popBody:before, .popOver[x-placement="top"] .popBody:before {
    top: 100%;
    border-color: #FFF #FFF transparent transparent;
    box-shadow: 1px -1px 2px 0 rgba(0, 0, 0, 0.16);
}

.popOver[x-placement="top"] .popBody:before {
    left: 50%;
}

.popOver[x-placement="top-end"] .popBody:before {
    left: auto;
    right: -5px;
}

.popOver .popBody.right:before {
    top: 47%;
    left: 8px;
    border-color: transparent #FFF #FFF transparent;
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.16);
}

.popOver .popBody.bottom-start:before {
    left: 23px;
}

.enerjoyApp .react-datepicker-popper {
    z-index: 2;
}

.erj-toggle .button-toggle-title {
    font-weight: bold;
    margin-bottom: 15px;
}

.erj-toggle .toggle-button {
    position: relative;
    width: 54px;
    height: 32px;
    margin: auto;
}

.erj-toggle .back {
    width: inherit;
    height: inherit;
    background-color: #DDE1E7;
    border-radius: 25px;
}

.erj-toggle .toggle {
    outline: none;
    cursor: pointer;
    color: #E55B63;
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 32px;
    background-color: #FFFFFF;
    border-radius: 50%;
    border: 1px solid #AAB3C1;
}

.erj-toggle .toggle.active {
    right: 0;
    left: unset;
    color: #75D798;
}

.erj-toggle .toggle:disabled {
    cursor: not-allowed;
    background-color: #F3F5F9;
}

.erj-toggle .eye_cross,
.erj-toggle .eye {
    font-size: 35px;
    position: absolute;
}

.erj-toggle .eye {
    right: -4px;
    top: -3px;
}

.erj-toggle .eye_cross {
    right: -1px;
    top: -6px;
}

/*start erj-user-selector*/
.erj-user-selector .users-container {
    margin-top: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}

.erj-user-selector .group-by {
    text-align: center;
    background: #6ABEDD;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    line-height: 30px;
}

.erj-user-selector .users-container .header-line {
    height: 40px;
    background-color: hsla(283, 60%, 66%, 1);
    display: flex;
    align-items: center;
    color: #FFFFFF;
    padding-left: 30px;
    justify-content: space-between;
    padding-right: 21px;
}

.erj-user-selector .header-line .arrows button {
    font-size: 13px;
    height: 37px;
    width: 37px;
    font-weight: bold;
}

.erj-user-selector .users-container .users {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 18px;
    min-height: 250px;
}

.erj-user-selector .Select-control {
    height: 50px;
    border-radius: unset;
}

.erj-user-selector .search-icon {
    color: #8A919E;
    height: 30px;
    width: 30px;
    margin-top: 7px;
}

.erj-user-selector .Select .Select-placeholder {
    top: 7px;
}

.erj-user-selector .Select input {
    height: 100%;
}

.erj-user-selector .Select-multi-value-wrapper {
    display: flex;
}

.erj-user-selector .right-container .user-option {
    height: 54px;
    align-items: center;
    display: flex;
}

.erj-user-selector .users-container .user-avatar {
    margin: 15px 18px;
    width: 45px;
    text-align: center;
    position: relative;
}

.erj-user-selector .users-container .userImg {
    height: 42px;
    width: 42px;
    display: block;
    border-radius: 50%;
}

.erj-user-selector .users-container .x_mark {
    background-color: #E55B63;
    color: #FFFFFF;
    height: 16px;
    width: 16px;
    font-size: 10px;
    position: absolute;
    right: -5px;
    top: -3px;
    padding: 0;
}

.erj-user-selector .user-option .userImg {
    height: 36px;
    width: 36px;
    margin: 0 12px;
    border-radius: 50%;
}

.erj-user-selector .user-option:hover,
.erj-user-selector .Select-option.is-focused {
    background-color: #EEF7FE;
    font-weight: bold;
}

/*erj-user-selector end*/

/*start erj-users-selector*/
.erj-user-selector.without-users-container .Select-control,
.erj-user-selector.without-users-container .is-open .Select-control {
    border-radius: 25px;
    width: 190px;
    height: 44px;
    margin: 5px auto;
}

.erj-user-selector.without-users-container .Select-control input {
    height: 100%;
    position: relative;
    top: -6px;
}

.erj-user-selector.without-users-container .Select-placeholder {
    top: 3px;
}

.erj-user-selector.without-users-container .search-icon {
    color: #8A919E;
    position: relative;
    top: 0;
}

.erj-user-selector.without-users-container .Select-multi-value-wrapper {
    display: flex;
}

.erj-user-selector.without-users-container .user-option,
.erj-user-selector.without-users-container .user-single-selection {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.erj-user-selector.without-users-container .user-single-selection {
    line-height: 35px;
}

.erj-user-selector.without-users-container .user-option .userImg,
.erj-user-selector.without-users-container .user-single-selection .userImg {
    height: 36px;
    width: 36px;
    margin: 0 12px;
    border-radius: 50%;
}

.erj-user-selector.without-users-container .user-option:hover,
.erj-user-selector.without-users-container .Select-option.is-focused {
    background-color: #EEF7FE;
    font-weight: bold;
}

.erj-user-selector.without-users-container .Select.has-value .Select-input {
    display: none !important;
}

/*erj-users-selector end*/

/*start date picker*/
.erj-calendar-container {
    position: relative;
}

.react-datepicker.erj-calendar {
    border: unset;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}

.react-datepicker.erj-calendar.hasMenu {
    display: flex;
}

.react-datepicker-popper[data-placement^="bottom"] .erj-calendar .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .erj-calendar .react-datepicker__triangle::before {
    border-bottom-color: #FFF;
}

.react-datepicker-popper[data-placement^="bottom"] .erj-calendar .react-datepicker__triangle::before {
    border-bottom-color: #aeaeae6b;
}

.erj-calendar .react-datepicker__header {
    background-color: #FFFFFF;
    border: unset;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    text-transform: uppercase;
    padding-top: 16px;
}

.erj-calendar .react-datepicker__month-container {
    padding: 0 28px 12px 28px;
}

.erj-calendar .react-datepicker__day-name,
.erj-calendar .react-datepicker__day {
    font-size: 12px;
}

.erj-calendar .react-datepicker__current-month {
    font-size: 16px;
    color: #858D98;
    height: 36px;
}

.erj-calendar .react-datepicker__day, .erj-calendar .react-datepicker__day:hover {
    border-radius: unset;
}

.erj-calendar .react-datepicker__day {
    border-top: 2px solid #FFFFFF;
    border-bottom: 2px solid #FFFFFF;
}

.erj-calendar .react-datepicker__day-names .react-datepicker__day-name {
    color: #B2BECC;
    font-size: 12px;
}

.erj-calendar .react-datepicker__day-name,
.erj-calendar .react-datepicker__day {
    margin: 0 6px;
    position: relative;
    user-select: none;
}

.erj-calendar .react-datepicker__week {
    margin: 4px 0;
}

.erj-calendar .react-datepicker__day--in-range, .erj-calendar .react-datepicker__day--in-range:hover {
    background-color: unset;
    color: #000;
    border-top: 2px solid #6724C6;
    border-bottom: 2px solid #6724C6;
}

.erj-calendar .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
    border-top: 2px solid #6724C6;
    border-bottom: 2px solid #6724C6;
    background-color: #d4c2f2;
    color: #000;
}

.erj-calendar .react-datepicker__day--selecting-range-start, .erj-calendar .react-datepicker__day--selecting-range-start:hover,
.erj-calendar .react-datepicker__day--keyboard-selected, .erj-calendar .react-datepicker__day--keyboard-selected:hover {
    background-color: unset;
    color: #000000;
}

.erj-calendar .react-datepicker__day--today {
    color: #6724C6;
}

.erj-calendar .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-start):not(.react-datepicker__day--keyboard-selected):after,
.erj-calendar .react-datepicker__day--in-range:not(.react-datepicker__day--range-start):after {
    content: '';
    position: absolute;
    width: 12px;
    height: 31px;
    background: inherit;
    left: -12px;
    top: -2px;
    border-top: 2px solid #6724C6;
    border-bottom: 2px solid #6724C6;
}

.erj-calendar .react-datepicker__day--range-start,
.erj-calendar .react-datepicker__day--selecting-range-start,
.erj-calendar .react-datepicker__day--range-start:hover,
.erj-calendar .react-datepicker__day--selecting-range-start:hover {
    border-left: 2px solid #6724C6;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.erj-calendar .react-datepicker__day--range-end,
.erj-calendar .react-datepicker__day--selecting-range-end,
.erj-calendar .react-datepicker__day--range-end:hover,
.erj-calendar .react-datepicker__day--selecting-range-end:hover {
    border-right: 2px solid #6724C6;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.erj-calendar .react-datepicker__navigation {
    top: 18px;
}

.erj-calendar .react-datepicker__navigation--previous, .erj-calendar .react-datepicker__navigation--previous:hover {
    border-right-color: #6724C6;
    left: 38px;
}

.erj-calendar .react-datepicker__navigation--next, .erj-calendar .react-datepicker__navigation--next:hover {
    border-left-color: #6724C6;
    right: 38px;
}

.erj-calendar .react-datepicker__day--outside-month {
    visibility: hidden;
}

.erj-calendar .menu {
    width: 200px;
    border-right: 1px solid #DDD;
}

.erj-calendar .menu button.timeFrame {
    display: block;
    width: 100%;
    background: #FFF;
    border: 0;
    border-bottom: 1px solid #DDD;
    font-size: 15px;
    height: 40px;
    padding-top: 2px;
    text-transform: capitalize;
}

.erj-calendar .menu button.timeFrame:hover, .erj-calendar .menu button.timeFrame.selected {
    position: relative;
    box-shadow: 0 0 2px hsla(265, 69%, 46%, 0.75);
}

.erj-calendar .menu button.timeFrame.selected {
    color: #6724C6;
    font-weight: bold;
}

/*date picker end*/

.erj-help-icon {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: #FFF;
    border-radius: 50%;
    box-shadow: 0 0 5px #AAA;
    margin-left: 5px;
    position: relative;
    cursor: pointer;
}

.erj-help-icon:after {
    content: '?';
    color: #6724c6;
    font-size: 10px;
    position: absolute;
    top: -1px;
    left: 4px;
}


.erj-time-input {
    display: flex;
    align-items: center;
    justify-content: center;
}

.erj-time-input.disabled {
    pointer-events: none;
}

.failed-password-rules {
    padding: 10px 0 0 0;
    margin: 0;
    color: #e53838;
    list-style: none;
}

.failedPasswordRuleIcon {
    font-size: 1.2em;
    position: relative;
    top: 0.2em;
    margin-right: 5px;
}

.erj-color-picker .background-color {
    cursor: pointer;
    border-radius: 5px;
    display: inline-block;
    width: 58px;
    height: 58px;
    box-shadow: inset 2px 3px 4px rgba(0, 0, 0, 0.25);
}

.erj-color-picker .background-color svg {
    fill: rgb(255 0 0 / 50%);
    font-size: 35px;
    margin: 12px;
}

.erj-color-picker .selected {
    display: inline-flex;
}

.erj-color-picker {
    cursor: pointer;
}

.erj-color-picker .arrow {
    transition: all;
    background: url("../../assets/arrow_down.png") no-repeat center;
    width: 25px;
}

.erj-color-picker .arrow.active {
    transform: rotate(30deg);
}

.erj-color-picker .colors .background-color {
    margin-right: 10px;
}
