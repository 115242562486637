/* Sticky footer styles
-------------------------------------------------- */
.pageContainer {
  position: relative;
  min-height: 100%;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 30px;
  line-height: 30px;
}
