.private-feed {
    background-color: #FFFFFF;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.05);
    padding: 0 26px;
    width: 822px;
}

.mob .private-feed {
    width: 100%;
    padding: 0;
    box-shadow: none;
    background: none;
}

.mob.floatingActivityHubWrapper .private-feed {
    flex: 0 1 100%;
}

.mob.floatingActivityHubWrapper .private-feed .postContent, .mob .private-feed .activityHub {
    height: 100%;
}

.mob .private-feed {
    width: 100%;
    box-shadow: none;
}

.private-feed .header-line {
    display: flex;
    justify-content: flex-end;
    padding: 18px 0;
}

.private-feed .header-line .tabs {
    height: 36px;
    min-height: unset;
}

.private-feed .header-line .tabs button {
    min-width: 96px;
}

.private-feed .header-line .tabs button span .tabLabel {
    color: #2a1566;
    z-index: 1;
    font-size: 15px;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    position: relative;
    top: -6px;
}

.private-feed .header-line .tabs button[aria-selected="true"] span .tabLabel {
    color: #FFFFFF;
}

.private-feed .header-line .indicator {
    height: 36px;
    background-color: #6724C6;
    border-radius: 25px;
    top: 0;
    transition: all 0s;
}

.private-feed .participantsFilter {
    height: 58px;
    background: #FFF;
    display: flex;
    border-top: 1px solid #dde1e7;
}

.private-feed .participantsFilter .filterLabel {
    color: #99abbc;
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 0 12px 0 25px;
}

.private-feed .participantsFilter .participant {
    min-width: auto;
    padding: 4px;
    outline: none;
}

.private-feed .participantsFilter .participant .teamIcon {
    height: 38px;
    width: 38px;
    font-size: 20px;
    background: #f3f5f9;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: rgb(167, 183, 198);
}

.private-feed .participantsFilter .participant.active .teamIcon {
    box-shadow: 0 0 0 2px #189aca;
}

.private-feed .participantsFilter .participant img {
    height: 37px;
    width: 37px;
    border-radius: 50%;
}

.private-feed .participantsFilter .participant.active img {
    box-shadow: 0 0 0 2px #189aca;
}

.private-feed .postsList {
    padding-top: 15px;
}

.private-feed .lockDot {
    display: flex;
    width: 21px;
    height: 21px;
    background: rgba(0, 0, 0, 0.25);
    align-items: center;
    justify-content: center;
    position: relative;
    top: 6px;
    border-radius: 50%;
}

.private-feed .lockDot.open {
    box-shadow: 0 0 13px 4px hsla(61, 100%, 50%, 1);
}

.private-feed .lockDot .lockIcon {
    height: 16px;
}

.private-feed .slick-active .lockDot {
    background: rgba(0, 0, 0, 0.75);
}

.private-feed .lockDot .lockIcon.closed path {
    fill: #777;
}

.private-feed .slick-active .lockDot .lockIcon.closed path {
    fill: #AAA;
}

.private-feed .inPostNotification {
    margin: -31px 0 0 auto;
    width: 496px;
    text-align: center;
    background: hsla(252, 66%, 96%, 1);
    border-radius: 20px 20px 0 0;
    padding: 5px 0;
    color: rgb(250, 45, 132);
    font-weight: bold;
}

.private-feed .post {
    margin-bottom: 74px;
    border-radius: 6px;
    position: relative;
}

.mob .private-feed .post {
    margin-bottom: 0;
    border-radius: 0;
}

.private-feed .postHeader {
    height: 26px;
    background: #c0a6e8;
    color: #FFF;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
}

.private-feed .postBody {
    margin: 0;
    box-shadow: 0 0 17px 5px rgba(94, 63, 186, 0.09);
    background: #FFF;
    display: flex;
    border-radius: 8px;
    min-height: 326px;
    color: #FFFFFF;
}

.mob .private-feed .postBody {
    display: block;
    position: relative;
    background: none;
}

.private-feed .postTitle {
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 21px;
    padding-bottom: 13px;
}

.mob .private-feed .postTitle {
    padding-bottom: 3px;
    padding-top: 5px;
}

.mob .private-feed .activity_def_id_9 .postTitle {
    display: none;
}

.private-feed .postTimeAgo {
    color: #979797;
    text-align: right;
    padding: 16px 23px 0 0;
}

.mob .private-feed .postTimeAgo {
    display: none;
}

/*start postmeta color by def id*/
.private-feed .activity_def_id_2 .postMeta {
    background-color: #283046;
    display: block;
}

.private-feed .activity_def_id_8 .postMeta {
    background-color: #703DC2;
}

.private-feed .activity_def_id_9 .postMeta {
    background-color: #C4A94A;
}

.private-feed .activity_def_id_13 .postMeta {
    background-color: #7136C5;
}

.private-feed .activity_def_id_14 .postMeta {
    background-color: #00A7CB;
}

.private-feed .activity_def_id_15 .postMeta {
    background-color: #FA487D;
}

.private-feed .change_points_pos .postMeta,
.private-feed .user_received_coins_reminder .postMeta {
    background-color: #0248D6;
}

.mob .private-feed .activity-symbol svg {
    width: 75%;
    height: 75%;
}

.private-feed .activity-symbol .contestIcon {
    width: 42px;
    height: 53px;
}

.private-feed .activity-symbol .teamWorkIcon {
    height: 47px;
    width: 33px;
}

.private-feed .activity-symbol .teamWorkIcon #heads,
.private-feed .activity-symbol .teamWorkIcon #connections {
    fill: #24D17E;
}

.private-feed .activity-symbol .teamWorkIcon #shirts {
    fill: #39BBD7;
}

.private-feed .activity-symbol .personalChallengeIcon {
    height: 47px;
    width: 33px;
}

.private-feed .activity-symbol .crownWithStarsIcon {
    height: 47px;
    width: 45px;
}

.private-feed .activity-symbol .dailyGoalIcon {
    height: 47px;
    width: 45px;
}

.private-feed .activity-symbol .earlyWinnersIcon {
    height: 33px;
    width: 56px;
}

.private-feed .congrats .activity-symbol .wheelOfFortuneIcon #bg {
    fill: #FFC107;
}

.private-feed .activity-symbol .wheelOfFortuneIcon {
    width: 40px;
    height: 43px;
}

.private-feed .activity-symbol .coinsPostIcon {
    width: 40px;
    height: 43px;
}

.private-feed .activity-symbol .rankingUpdateIcon {
    width: 46px;
    height: 36px;
}

/*postmeta color by def id end*/

.private-feed .postMeta {
    padding: 0 20px 30px 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 274px;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    text-align: center;
}

.mob .private-feed .postMeta {
    width: auto;
    height: 158px;
    border-bottom-left-radius: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

.mob .private-feed .activity_def_id_9 .postMeta {
    flex-direction: row;
    height: 145px;
}

.mob .private-feed .post_change_points_pos .postMeta {
    height: 120px;
}

.mob .private-feed .post_change_points_pos .postMeta .postMainDetails {
    width: 100%;
}

.mob .private-feed .activity_def_id_9 .postMeta .postMainDetails {
    padding-left: 10px;
    padding-bottom: 10px;
}

.private-feed .postMeta .activity-symbol {
    box-shadow: 0 0 17px 5px rgba(94, 63, 186, 0.09);
    width: 78px;
    height: 78px;
    border-radius: 50%;
    margin: -45px auto 7px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
}

.mob .private-feed .postMeta .activity-symbol {
    margin: 0;
    position: absolute;
    top: -1px;
    left: 0;
    width: 39px;
    height: 39px;
    box-shadow: none;
    border-radius: 0;
    border-bottom-right-radius: 50%;
    padding: 0 5px 5px 0;
}

.private-feed .postContent {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.mob .private-feed .post .postContent {
    padding: 5px 10px;
    height: 202px;
}

.mob .private-feed .post .activity_def_id_9 .postContent {
    padding: 0;
    height: 215px;
}

.mob .private-feed .post.post_change_points_pos .postContent {
    padding: 0;
    height: 240px;
}

.mob .private-feed .postContent .activity-container {
    height: 144px;
    position: relative;
}

.private-feed .postContent .activity-container:not(.personal-activity) {
    margin: auto;
    text-align: center;
}

.mob .private-feed .postContent .activity-container:not(.personal-activity) {
    margin: 0;
}

.mob .private-feed .postContent .personal-activity {
    padding: 0;
    height: 152px;
}

.mob .private-feed .activity_def_id_9 .postContent .activity-container {
    height: 100%;
}

.private-feed .postContent .activityStartContent.wheel-of-fortune {
    position: relative;
}

.mob .private-feed .postContent .activityStartContent.wheel-of-fortune {
    margin-top: -107px;
}

.private-feed .postContent .activityStartContent.wheel-of-fortune.fade-out {
    opacity: 0;
    transition: 0.4s opacity;
}

.private-feed .postContent .activity-container:not(.personal-activity) .activityEndedContent {
    padding-top: 30px;
}

.mob .private-feed .postContent .activity-container .activityEndedContent {
    display: flex;
    padding: 0;
    flex-wrap: wrap;
}

.private-feed .postContent .activity-container .participants.oneTeam .participants-scroller {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 360px;
}

.private-feed .postContent .personal-activity {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 31px;
    width: 100%;
    height: 100%;
}

.private-feed .postContent .activity-container.hidden {
    display: none;
}

.private-feed .postContent .postContentTabs {
    height: 56px;
    color: #39257A;
    border-bottom: 2px solid #F5F5F5;
}

.private-feed .postContent .postContentTabs .tabLabel {
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
}

.private-feed .postContent .postContentTabs button[aria-selected="true"] span .tabLabel {
    color: #9824c6;
}

.private-feed .postContent .postContentTabs button[aria-selected="true"] .icon- {
    color: #33D085;
}

.private-feed .postContent .postContentTabs .icon- {
    margin-right: 17px;
    font-size: 21px;
    vertical-align: top;
}

.private-feed .postContent .postContentTabs .star-ranking-icon {
    font-size: 23px;
}

.private-feed .postContent .postContentTabs > div {
    height: 100%;
}

.private-feed .postContent .postContentTabs .indicator {
    height: 3px;
    transition: all 0s;
}

.private-feed .postContent .post-container {
    margin: auto;
    text-align: center;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
}

.private-feed .postContent .post-container.change_points_pos {
    width: 420px;
}

.mob .private-feed .postContent .post-container {
    width: auto;
    margin: 0;
}

.private-feed .postContent .post-container .post-title {
    color: #0045D1;
    font-size: 15px;
    text-align: left;
    margin-bottom: 18px;
}

.private-feed .postContent .post-container .users-table {
    width: 420px;
    margin-bottom: 30px;
}

.mob .private-feed .postContent .post-container .users-table {
    width: 100%;
    box-shadow: 0 0 4px rgba(255, 255, 255, 0.5);
}

.private-feed .postContent .post-container .users-table .users-table-header {
    background-color: #0248D6;
    display: flex;
    height: 38px;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    text-transform: uppercase;
    padding: 0 28px 0 50px;
}

.private-feed .postContent .post-container .users-table .table-body-row {
    color: #102F86;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.private-feed .postContent .post-container .users-table .table-body-row .ranking-details {
    border-bottom: 1px dotted;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 365px;
    height: 70px;
}

.private-feed .postContent .post-container .users-table .table-body-row .ranking-details:last-child {
    border-bottom: 0;
}

.mob .private-feed .postContent .post-container .users-table .table-body-row .ranking-details {
    height: 67px;
    width: auto;
    flex: 1 1;
    padding: 0 0 0 13px;
}

.private-feed .postContent .post-container .users-table .table-body-row .rank-pos {
    font-size: 16px;
}

.private-feed .postContent .post-container .users-table .table-body-row .ranking-details-container {
    display: flex;
    align-items: center;
    font-size: 15px;
}

.private-feed .postContent .post-container .users-table .table-body-row .ranking-details-container .monthly-points {
    color: #945FD9;
}

.private-feed .postContent .post-container .users-table .table-body-row .ranking-details img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-right: 20px;
}

.private-feed .postContent .post-container .users-table .table-body-row .ranking-details .user-details {
    text-align: left;
}

.private-feed .postContent .post-container .users-table .table-body-row .ranking-details .mySelf {
    color: #22E488;
}

.private-feed .postContent .post-container .users-table .table-body-row .predicted-coins {
    display: flex;
    width: 100px;
}

.private-feed .postContent .post-container .users-table .table-body-row .coinsIcon {
    width: 25px;
    height: 25px;
    margin-right: 15px;
}

.private-feed .postMeta .relatedUserDetails {
    display: flex;
    align-items: center;
    font-size: 15px;
    padding: 12px 0 17px 30px;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
}

.mob .private-feed .postMeta .relatedUserDetails {
    display: none;
}

.private-feed .postMeta .relatedUserDetails img {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    margin-right: 18px;
}

.private-feed .postMeta .inviteText {
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    text-align: left;
    font-size: 14px;
    padding-bottom: 47px;
}

.mob .private-feed .postMeta .inviteText {
    padding: 0 5px 4px;
    line-height: 20px;
}

.private-feed .postMeta .info-container {
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    margin-top: 20px;
    text-align: center;
}

.mob .private-feed .postMeta .info-container {
    text-align: left;
    display: flex;
    margin-top: 0;
    align-items: center;
}

.private-feed .postMeta .info-container .question-mark {
    color: #22E488;
    border: 2px solid #22E488;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    display: inline-block;
    text-align: center;
    line-height: 18px;
    margin-bottom: 5px;
    flex-basis: 22px;
    flex-grow: 0;
    flex-shrink: 0;
}

.private-feed .postMeta .info-container .info-text {
    display: block;
}

.mob .private-feed .postMeta .info-container .info-text {
    display: block;
    padding-left: 10px;
}

.private-feed .activity_def_id_2 .postMeta .inviteText {
    padding-bottom: 69px;
}

.private-feed .postMeta .inviteText.centered {
    text-align: center;
}

.private-feed .postMeta .inviteText.congrats {
    display: flex;
    align-items: flex-end;
    padding-top: 20px;
    padding-bottom: 0;
    margin-bottom: 60px;
}

.mob .private-feed .postMeta .inviteText.congrats {
    padding: 0;
    margin: 0;
}

.private-feed .postMeta .inviteText.congrats .message-balloon {
    background-color: #FFFFFF;
    color: #000000;
    border-radius: 17px;
    padding: 15px;
    position: relative;
    max-width: 175px;
}

.mob .private-feed .postMeta .inviteText.congrats .message-balloon {
    padding: 5px 10px
}

.private-feed .postMeta .inviteText.congrats .message-balloon .chat-bubble-connector-icon {
    color: #FFFFFF;
    position: absolute;
    left: -11px;
    bottom: 10px;
}

.mob .private-feed .postMeta .inviteText.congrats .message-balloon .chat-bubble-connector-icon {
    left: -14px;
}

.private-feed .postMeta .inviteText.congrats img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 14px;
}

.private-feed .postMeta .inviteText .relatedUserDetails {
    display: inline-flex;
    padding: 0 2px;
    margin-bottom: 13px;
    position: relative;
    top: 13px;
}

.private-feed .postMeta .inviteText .relatedUserDetails img {
    width: 37px;
    height: 37px;
    margin-right: 7px;
}

.private-feed .spin-button button {
    width: 205px;
    height: 46px;
    color: #FFFFFF;
    font-size: 16px;
    padding: 0 45px;
    border-radius: 23px;
    justify-content: space-evenly;
    box-shadow: inset 0 3px rgba(9, 29, 85, 0);
    background: linear-gradient(to right, #2986b3 16%, #2986b3 42%, #09c1cb);
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    transition: 0.4s opacity;
}

.mob .private-feed .spin-button button {
    position: absolute;
    top: 200px;
    left: 50%;
    z-index: 2;
    transform: translateX(-50%);
    background: none;
    color: #09c1cb;
    width: 160px;
}

.private-feed .spin-button button .icon- {
    font-size: inherit;
    width: auto;
    height: auto;
}

.private-feed .spin-button button:disabled {
    opacity: 0.5;
}

.private-feed .spin-button button:disabled.hidden {
    opacity: 0;
}

.private-feed .challengeMeta, .private-feed .autoFeedbackMeta {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.mob .private-feed .challengeMeta {
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 1;
    align-items: flex-end;
    justify-content: space-between;
}

.mob .private-feed .post_change_points_pos .challengeMeta {
    float: left;
    width: 35%;
}

.private-feed .challengeMeta .win_condition_text {
    padding-bottom: 35px;
}

.mob .private-feed .challengeMeta .win_condition_text {
    padding-bottom: 5px;
    flex: 0 0 100%;
}

.private-feed .postMeta .winReq, .private-feed .postMeta .winRes {
    height: 61px;
    width: 193px;
    text-align: center;
    border: 2px solid;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
}

.mob .private-feed .postMeta .winReq, .mob .private-feed .postMeta .winRes {
    width: auto;
    height: 52px;
    flex-direction: column-reverse;
    border: 0;
    flex: 1 1 33%;
    justify-content: flex-end;
}

.private-feed .postMeta .winReq .metricTitleArea, .private-feed .postMeta .winRes .pointsTitleArea {
    margin-top: -17px;
    font-size: 0;
}

.private-feed .postMeta .winReq .metricTitle, .private-feed .postMeta .winRes .pointsTitle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    background: #24D17E;
    border-radius: 8px;
    font-size: 14px;
    height: 31px;
    padding: 0 12px;
    z-index: 1;
}

.mob .private-feed .postMeta .winReq .metricTitleArea, .mob .private-feed .postMeta .winRes .pointsTitleArea {
    margin-top: 0;
}

.mob .private-feed .postMeta .winReq .metricTitle, .mob .private-feed .postMeta .winRes .pointsTitle {
    background: none;
    margin: 0;
    height: auto;
    font-size: 11px;
}


.private-feed .postMeta .winRes .pointsTitle .trophyFullIcon {
    width: 17px;
    margin-right: 8px;
}

.private-feed .postMeta .winRes .pointsTitle .trophyFullIcon #cup {
    fill: #FFFFFF;
    stroke: #FFFFFF;
}

.private-feed .postMeta .winReq .metricTitle .targetIcon {
    font-size: 19px;
    margin-right: 2px;
}

.private-feed .postMeta .winReq .reqVal, .private-feed .postMeta .winRes .resVal {
    font-size: 27px;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    display: flex;
    justify-content: center;
}

.mob .private-feed .postMeta .winReq .reqVal, .mob .private-feed .postMeta .winRes .resVal {
    font-size: 24px;
    line-height: 30px;
}

.private-feed .postMeta .winReq .reqVal .value {
    margin: 0 5px;
}

.private-feed .postMeta .winReq .reqVal .arrow.hidden {
    opacity: 0;
}

.private-feed .postMeta .winReq .reqVal .pos-arrow-up {
    color: #27DA85;
}

.private-feed .postMeta .winReq .reqVal .pos-arrow-down {
    color: #FE1C1D;
}

.private-feed .postMeta .winReq .reqVal.unsetGoal {
    font-size: 18px;
    margin-top: 5px;
    font-weight: bold;
}

.private-feed .postMeta .challengeMetaSummary {
    display: flex;
    width: 207px;
    padding-top: 24px;
    justify-content: space-around;
}

.mob .private-feed .postMeta .challengeMetaSummary {
    padding-top: 0;
    width: auto;
    flex: 1 1 66%;
    justify-content: center
}

.private-feed .postMeta .challengeMetaSummary .pointsIcon {
    height: 31px;
    width: 31px;
}

.private-feed .postMeta .challengeMetaSummary .hourGlassIcon {
    height: 27px;
    width: 27px;
    margin-bottom: 3px;
}

.private-feed .postMeta .challengeMetaSummary .giftIcon {
    height: 36px;
    position: relative;
    top: -9px;
}

.private-feed .postMeta .challengeMetaSummary .giftIcon #top-left-ribbon,
.private-feed .postMeta .challengeMetaSummary .giftIcon #top-right-ribbon,
.private-feed .postMeta .challengeMetaSummary .giftIcon #vertical-ribbon {
    stroke: rgb(56, 237, 150);
}

.private-feed .postMeta .challengeMetaSummary .giftIcon #box-bottom,
.private-feed .postMeta .challengeMetaSummary .giftIcon #box-top {
    stroke: #FFF;
}

.private-feed .postMeta .challengeMetaSummary .coinsIcon {
    width: 42px;
    position: relative;
    top: -5px;
}

.private-feed .postMeta .challengeMetaSummary .coinsIcon #coin {
    fill: #FFF;
    stroke: #FFF;
}

.private-feed .postMeta .challengeMetaSummary #trophy,
.private-feed .postMeta .challengeMetaSummary #hourglass {
    fill: #FFFFFF;
    stroke: #FFFFFF;
}

.private-feed .postMeta .summaryItem {
    text-align: center;
}

.mob .private-feed .postMeta .summaryItem {
    flex: 1 1 40%;
    text-align: center;
    position: relative;
    padding: 0 15px;
    height: 52px;
}

.mob .private-feed .postMeta .summaryItem:last-child {
    flex: 1 1 60%;
    padding: 0 10px;
}

.mob .private-feed .postMeta .summaryItem:before {
    content: '';
    display: block;
    width: 1px;
    height: 50%;
    position: absolute;
    top: 25%;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    left: 0;
}

.private-feed .postMeta .summaryLabel {
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    padding: 0 2px;
}

.private-feed .postMeta .summaryLabel.finalizing_results {
    white-space: break-spaces;
}

.mob .private-feed .postMeta .summaryItem:not(.timeLeft) .summaryLabel {
    justify-content: center;
}

.private-feed .postMeta .summaryValue {
    font-size: 24px;
    line-height: 30px;
}

.private-feed .postMeta .summaryValue.completed {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 16px;
}

.mob .private-feed .postMeta .summaryValue.completed {
    margin-bottom: 0;
    line-height: 26px;
}

.private-feed .postMeta .summaryValue.completed .timeAgo {
    font-size: 15px;
    position: relative;
    top: -3px;
    line-height: 0;
}

.mob .private-feed .postMeta .summaryValue.completed .timeAgo {
    font-size: 13px;
    top: -4px;
}

.private-feed .postMeta .activitySummaryTitle {
    font-size: 16px;
    color: #414b6b;
    text-align: center;
    padding-top: 28px;
}

.private-feed .postMeta .activitySummarySubTitle {
    font-size: 14px;
    color: #414b6b;
    text-align: center;
}

.private-feed .postContent .pleaseCheckInMsg {
    color: #102F86;
    font-size: 14px;
    font-weight: bold;
}

.private-feed .postContent .activityStartContent :not(.groupActivity) .pleaseCheckInMsg {
    padding: 47px 0 13px 0;
}

.private-feed .postContent .activityStartContent.groupActivity .pleaseCheckInMsg {
    padding-bottom: 17px;
}

.mob .private-feed .postContent .activityStartContent.groupActivity .pleaseCheckInMsg {
    padding-bottom: 5px;
}

.private-feed .postContent .personalActivity {
    text-align: center;
    width: 100%;
}

.private-feed .postContent .personalActivity .pleaseCheckInMsg {
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    padding: 18px 30px 7px 30px;
    font-size: 18px;
}

.mob .private-feed .postContent .personalActivity .pleaseCheckInMsg {
    padding: 0 0 15px 0;
}

.private-feed .postContent .checkInBtn, .private-feed .postContent .openHubBtn {
    width: 293px;
    margin-top: 25px;
    height: 46px;
    border-radius: 25px;
    background-color: #27DA85;
    font-size: 15px;
}

.private-feed .postContent .checkInBtn {
    margin-bottom: 25px;
}

.mob .private-feed .postContent .checkInBtn {
    width: 100%;
    position: absolute;
    left: 0;
    border-radius: 0;
    bottom: 0;
    margin: 0;
}

.private-feed .postContent .team {
    text-align: center;
}

.private-feed .postContent .vs-sign {
    border-radius: 50%;
    height: 27px;
    width: 27px;
    margin: 0 5px;
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background: #39257A;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
}

.private-feed .activityStartContent > .vs-sign {
    height: 42px;
    width: 42px;
    margin: 0 auto 10px;

}

.private-feed .flex-middle {
    display: flex;
}

.private-feed .postContent .flex-middle.center {
    justify-content: center;
    position: relative;
    z-index: 1;
}

.private-feed .postContent .participants:not(.oneTeam) .teamParticipants {
    display: flex;
    flex-direction: column;
}

.private-feed .postContent .flex-middle.center .focusBox.participants:not(.oneTeam) {
    align-items: center;
}

.private-feed .postContent .focusBox.participants:not(.oneTeam) {
    display: flex;
    width: 341px;
    overflow-x: hidden;
    flex-direction: column;
}

.private-feed .postContent .focusBox.participants:not(.oneTeam) .participants-scroller {
    display: flex;
    align-items: center;
    margin: 0 auto;
}

.private-feed .postContent .pagination-btn {
    color: #39257A;
    background-color: transparent;
    border: 0;
    font-size: 25px;
    cursor: pointer;
}

.private-feed .postContent .pagination-btn:hover {
    color: #00ACD1;
}

.private-feed .postContent .bold_arrow_left {
    padding-right: 28px;
}

.private-feed .postContent .bold_arrow_right {
    padding-left: 28px;
}

.private-feed .postContent .oneTeam .team {
    flex: 1;
    display: flex;
}

.private-feed .postContent .participantsList:not(.single-group) .participantsRow {
    padding: 0 45px 0 11px;
}

.mob .private-feed .postContent .participantsList:not(.single-group) .participantsRow {
    padding: 0 10px 0 0;
}

.private-feed .postContent .participantsRow .users-performance {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.private-feed .postContent .participantsList.single-group .participantsRow.team .users-performance {
    flex-direction: column;
}

.private-feed .postContent .users-performance .performance {
    color: #3b277b;
}

.private-feed .postContent .oneTeam .teamParticipants {
    flex: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
}

.private-feed .postContent .leadingTeamParticipants {
    text-align: center;
}

.private-feed .postContent .vsCol {
    text-align: center;
    max-width: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.private-feed .postContent .vsCol:after {
    content: 'VS';
    color: #414b6b;
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    padding-top: 9px;
    width: 45px;
    height: 45px;
    border: 1px solid #a7b7c6;
    border-radius: 50%;
    text-align: center;
}

.private-feed .postContent .participant, .activityParticipantsPopOver .participant {
    position: relative;
    display: inline-block;
    padding: 7px;
}

.mob .private-feed .postContent .participant, .mob .activityParticipantsPopOver .participant {
    padding: 2px 7px;
}

.private-feed .postContent .teamParticipants .checked-in, .private-feed .postContent .leadingTeamParticipants .checked-in {
    font-size: 10px;
    white-space: nowrap;
    display: block;
}

.private-feed .postContent .teamParticipants .checked-in, .private-feed .postContent .leadingTeamParticipants .centerName {
    font-size: 0.75em;
    display: block;
}

.private-feed .postContent .participant img, .activityParticipantsPopOver .participant img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.private-feed .postContent .participant.checked img, .activityParticipantsPopOver .participant.checked img {
    border: 2px solid #33D085;
}

.private-feed .postContent .participant .checkedInIcon, .activityParticipantsPopOver .participant .checkedInIcon {
    width: 18px;
    height: 18px;
    font-size: 11px;
    color: #FFF;
    border-radius: 50%;
    overflow: hidden;
    background: #33D085;
    display: block;
    padding: 4px 0 0 0;
    letter-spacing: 2px;
    position: absolute;
    top: 3px;
    right: 3px;
}

.private-feed .postContent .checkInSuccessMsg {
    text-align: center;
}

.mob .private-feed .postContent .checkInSuccessMsg {
    display: flex;
    flex-wrap: wrap;
}

.private-feed .postContent .checkInSuccessMsg.fade-in {
    position: static;
    opacity: 1;
    transition: 1s ease-in;
}

.private-feed .postContent .checkInSuccessMsg.fade-out {
    opacity: 0;
}

.private-feed .postContent .checkInSuccessMsg .checkedInIcon {
    color: #27DA85;
    font-size: 93px;
}

.mob .private-feed .postContent .checkInSuccessMsg .checkedInIcon {
    font-size: 64px;
    flex: 20% 0 0;
}

.private-feed .postContent .checkInSuccessMsg .congrats-message {
    color: #BEA533;
    font-size: 25px;
    font-weight: bold;
}

.mob .private-feed .postContent .checkInSuccessMsg .congrats-message {
    color: #FFF;
    width: 100%;
    position: relative;
    top: 32px;
    background: linear-gradient(to bottom, rgba(40, 48, 70, 0.75) 80%, rgba(40, 48, 70, 0));
    padding-bottom: 10px;
    margin-top: -10px;
    z-index: 2;
}

.private-feed .postContent .checkInSuccessMsg .decoration {
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("../../assets/wheel_deco.png") no-repeat center;
    background-size: 399px 299px;
    width: 399px;
    height: 299px;
    margin: -14px auto 32px auto;
}

.mob .private-feed .postContent .checkInSuccessMsg .decoration {
    transform: scale(0.9);
}

.private-feed .postContent .checkInSuccessMsg .wheel-of-fortune-win:after {
    position: absolute;
    content: '';
    width: 233px;
    height: 233px;
    left: -34px;
    top: -34px;
    border-radius: 50%;
    background: linear-gradient(to right, #2986b3 35%, #09c1cb);
    opacity: 0.18;
}

.private-feed .postContent .checkInSuccessMsg .wheel-of-fortune-win {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 165px;
    height: 165px;
    border-radius: 50%;
    background: linear-gradient(to right, #2986b3 35%, #09c1cb);
}

.private-feed .postContent .checkInSuccessMsg .wheel-of-fortune-win .trophySemiFillIcon {
    width: 67px;
    height: 66px;
}

.private-feed .postContent #wheel_canvas {
    position: absolute;
    left: 65px;
    top: 31px;
}

.mob .private-feed .postContent #wheel_canvas {
    left: 50%;
    transform: scale(0.9) translateX(-55%);
}

.private-feed .postContent .checkInSuccessMsg .wheel-of-fortune-win .details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
}

.private-feed .postContent .checkInSuccessMsg .wheel-of-fortune-win .points {
    font-size: 29px;
    font-weight: bold;
}

.private-feed .postContent .checkInSuccessMsg .wheel-of-fortune-win:before {
    position: absolute;
    content: '';
    width: 199px;
    height: 199px;
    left: -17px;
    top: -17px;
    border-radius: 50%;
    background: linear-gradient(to right, #2986b3 35%, #09c1cb);
    opacity: 0.39;
}

.private-feed .postContent .checkInSuccessMsg .successCheckingIn, .private-feed .postContent .checkInSuccessMsg .goodLuckText {
    color: #414b6b;
    font-size: 18px;
    text-align: center;
    margin-top: 22px;
}

.mob .private-feed .postContent .checkInSuccessMsg .successCheckingIn, .mob .private-feed .postContent .checkInSuccessMsg .goodLuckText {
    margin: 0;
    flex: 1 1 80%;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mob .private-feed .postContent .checkInSuccessMsg .successCheckingIn {
    height: 64px;
    display: flex;
    align-items: center;
    text-align: left;
    padding-left: 15px;
}

.private-feed .postContent .checkInSuccessMsg .goodLuckText {
    font-weight: bold;
    margin-top: 3px;
}

.private-feed .postContent .sendGoodLuckMsg {
    padding: 20px;
}

.private-feed .postContent .sendGoodLuckMsg a {
    font-size: 14px;
    color: #414b6b;
    text-decoration: underline;
}

.private-feed .postContent .sendGoodLuckMsg a:hover {
    color: #189aca;
}

.private-feed .postContent .sendGoodLuckMsg .wavingIcon {
    background: url("../../assets/waving_hand.svg") no-repeat center center;
    display: inline-block;
    width: 23px;
    height: 23px;
    background-size: contain;
    position: relative;
    top: -2px;
    left: 5px;
}

.private-feed .postContent .activityInProgressContent .focusBox {
    min-height: 121px;
}

.private-feed .postContent .activityInProgressContent.personalActivity .focusBox {
    min-height: 231px;
}

.mob .private-feed .postContent .activityInProgressContent.personalActivity .focusBox {
    min-height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.private-feed .postContent .activityInProgressContent.personalActivity .postUpdateIcon {
    height: 87px;
    width: 79px;
}

.mob .private-feed .postContent .activityInProgressContent.personalActivity .postUpdateIcon {
    width: auto;
    height: auto;
    flex: 0 0 25px;
}

.private-feed .postContent .activityInProgressContent.personalActivity .postUpdateIcon #arrows {
    fill: #FD3A8D;
}

.private-feed .postContent .activityInProgressContent.personalActivity .pleaseCheckInMsg {
    padding-top: 3px;
}

.mob .private-feed .postContent .activityInProgressContent.personalActivity .pleaseCheckInMsg {
    padding: 0 15px;
}

.private-feed .postContent .personal-activity .progressRow {
    position: relative;
    height: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 75px;
    padding-bottom: 90px;
}

.mob .private-feed .postContent .personal-activity .progressRow {
    padding-bottom: 25px;
    flex: 0 0 100%;
    padding-left: 30px;
}

.private-feed .postContent .personal-activity .progressRow .indicatorLabel {
    display: inline-block;
    position: absolute;
    top: 13px;
    font-size: 16px;
    color: #2a1566;
    font-weight: bold;
    line-height: 18px;
}

.private-feed .postContent .personal-activity .progressRow .indicatorLabel:not(.declareText) .label {
    font-weight: normal;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 13px;
}

.private-feed .postContent .personal-activity .progressRow .indicatorLabel.declareText {
    top: auto;
    bottom: 0;
}

.private-feed .postContent .personal-activity .progressRow .livePerformText .content {
    padding: 2px 8px;
    background-color: #fff;
    top: -65px;
}

.mob .private-feed .postContent .personal-activity .progressRow .livePerformText .content {
    background: none;
}

.private-feed .postContent .personal-activity .progressRow .livePerformText .content .label {
    display: inline-block;
}

.private-feed .postContent .personal-activity .progressRow .finalPerformText .content {
    padding: 2px 8px;
    height: 47px;
    background-color: #fff;
    border: solid 2px #2a1566;
    top: -75px;
}

.private-feed .postContent .progressRow .finalPerformText .content:before,
.private-feed .postContent .progressRow .finalPerformText .content:after {
    position: absolute;
    content: '';
    display: block;
    border-top: 8px solid black;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    left: 50%;
    bottom: -8px;
    margin-left: -5px;
}

.private-feed .postContent .progressRow .finalPerformText .content:after {
    border-top: 7px solid #FFF;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    bottom: -6px;
    margin-left: -3px;
}

.private-feed .postContent .progressRow .performance {
    color: #183589;
    font-size: 30px;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    padding-left: 20px;

}

.private-feed .postContent .progressRow .performance .points-label {
    font-size: 11px;
    text-transform: uppercase;
    margin-top: -2px;
    text-align: right;
}

.private-feed .postContent .progressRow .performance .green-symbol {
    color: #27DA85;
    padding-right: 5px;
}

.private-feed .postContent .progressBar {
    display: flex;
    flex: 1;
    height: 20px;
    width: 343px;
    background: #FFFFFF;
    border-radius: 14px;
    position: relative;
    border: 3px solid #e3e5ea;
}

.mob .private-feed .postContent .progressBar {
    width: 100%;
}

.private-feed .postContent .progressRow .indicator {
    width: 24px;
    height: 24px;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #FFF;
    background: #6724c6;
    top: -5px;
    margin-left: -12px;
    z-index: 2;
    transition: all 0s;
    cursor: pointer;
}

.private-feed .postContent .progressRow .indicatorDesc {
    white-space: nowrap;
}

.private-feed .postContent .progressRow .indicatorDesc.filler {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
}

.private-feed .postContent .progressRow .indicator .popOverLinkContainer > div {
    margin-top: 25px;
}

.private-feed .postContent .progressRow .indicator .on-fire {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-weight: normal;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 13px;
    color: #2a1566;
    white-space: nowrap;
    margin-top: 26px;
}

.private-feed .postContent .progressRow .indicator .on-fire .pointsIcon {
    width: 14px;
    height: 14px;
    position: relative;
    top: 2px;
    margin: 0 2px;
}

.private-feed .postContent .progressRow .indicator .on-fire .pointsIcon #trophy {
    fill: #FA2D84;
    stroke: #FA2D84;
}

.private-feed .postContent .progressRow .indicator .on-fire .green-symbol {
    color: #27DA85;
}

.private-feed .postContent .progressRow .indicator .popOverLinkContainer a {
    text-decoration: unset;
}

.private-feed .postContent .progressRow .indicator .popOverLinkContainer .fire-icon {
    font-size: 27px;
    background: linear-gradient(to top, #E10000 3%, #FF3000 25%, #FFB400 92%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent
}

.private-feed .postContent .progressRow .indicator .popOverLinkContainer .generalPopOver {
    color: #414b6b;
    white-space: nowrap;
    left: 0;
}

.private-feed .postContent .progressRow .indicator .popOverLinkContainer .popBody {
    top: -10px;
    left: -58px;
}

.private-feed .postContent .progressRow .indicator.declareValIndicator {
    background: #27da85;
}

.private-feed .postContent .progressRow .popOverLinkContainer.win_req_step {
    position: absolute;
    z-index: 3;
    top: 25px;
    transform: translateX(-50%);
}

.private-feed .postContent .activityEndedContent .resultFeedback .win_req_step {
    margin: 15px;
}

.private-feed .postContent .win_req_step .popOverLink {
    background: #6724c6;
    padding: 5px;
    line-height: 0;
    display: block;
    border-radius: 50%;
}

.private-feed .postContent .win_req_step.active .popOverLink {
    box-shadow: 0 0 15px 2px #27da85;
}

.private-feed .postContent .win_req_step .giftIcon {
    width: 18px;
}

.private-feed .postContent .win_req_step .giftIcon #box-top,
.private-feed .postContent .win_req_step .giftIcon #box-bottom {
    fill: #fa487d;
    stroke: #FFF;
}

.private-feed .postContent .win_req_step .giftIcon #vertical-ribbon,
.private-feed .postContent .win_req_step .giftIcon #top-left-ribbon,
.private-feed .postContent .win_req_step .giftIcon #top-right-ribbon {
    stroke: #FFF;
    fill: #38ed96;
}

.private-feed .postContent .win_req_step .coinsIcon {
    width: 18px;
}

.private-feed .postContent .win_req_step .coinsIcon #coin {
    fill: #FFF;
    stroke: #FFF;
}

.private-feed .postContent .win_req_step .popOver .popBody:before {
    left: 50%;
    margin-left: 8px;
}

.private-feed .winReqProduct, .giftProductPopOver .winReqProduct {
    width: 200px;
    font-size: 14px;
    font-weight: normal;
}

.private-feed .winReqProduct .productImg, .giftProductPopOver .winReqProduct .productImg {
    width: 200px;
    height: 200px;
}

.private-feed .winReqProduct .productTitle, .giftProductPopOver .winReqProduct .productTitle {
    color: #2a1566;
    padding-top: 5px;
}


.private-feed .postContent .progressRow .fill {
    height: 100%;
    border-radius: 14px;
    position: absolute;
    top: 0;
    left: 0;
    background: #6724c6;
}

.private-feed .postContent .progressRow .fill.aboveWinReqFill {
    background: #27da85;
}

.private-feed .postContent .progressRow .indicatorLabel .content {
    position: relative;
    left: -50%;
    white-space: nowrap;
}

.private-feed .postContent .currentPerformance {
    position: relative;
    top: 3px;
    color: #414b6b;
    font-size: 24px;
}

.private-feed .postContent .currentPerformance .numLeads {
    font-size: 36px;
}

.private-feed .postContent .personalActivity .currentPerformance {
    font-size: 20px;
    top: -18px;
}

.private-feed .postContent .personalActivity .currentPerformance:before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #414b6b;
    margin-right: 6px;
}

.private-feed .postContent .personalActivity .currentPerformance .numLeads {
    font-size: 34px;
}

.private-feed .postContent .personalActivity .currentPointsProgress {
    font-size: 30px;
    position: relative;
    top: -35px;
}

.private-feed .postContent .personalActivity .plus {
    font-size: 24px;
}

.private-feed .postContent .chat-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    background: url("../../assets/geometry2.png");
}

.private-feed .postContent .chat-container .channelTabsRow {
    padding: 10px 21px;
}

.private-feed .postContent .chat-container .channelTabs {
    height: 37px;
    min-height: unset;
}

.private-feed .activityHub .chat-container .channelTabs .unread-counter {
    min-width: 17px;
    height: 17px;
    text-align: center;
    line-height: 17px;
    border-radius: 25px;
    background-color: #e63535;
    color: #FFFFFF;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 9px;
    padding: 0 6px;
    display: inline-block;
    position: relative;
    top: -1px;
    left: 7px;
}

.private-feed .postContent .chat-container .channelTabs button, .manager-feed .postContent .chat-container .channelTabs button:focus {
    height: 37px;
    min-height: 37px;
    z-index: 1;
    border-radius: 17px;
}

.private-feed .postContent .chat-container .channelTabs button span .tabLabel {
    color: #2a1566;
    z-index: 1;
    font-size: 14px;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    position: relative;
    top: -2px;
}

.private-feed .postContent .chat-container .channelTabs .indicator {
    height: 35px;
    border: 2px solid #6724C6;
    background: transparent;
    border-radius: 17px;
    top: 0;
    transition: all 0s;
}

.private-feed .postContent .chatArea {
    padding-left: 16px;
}

.private-feed .postContent .openHubBtn {
    margin-top: 26px;
}

.private-feed .postContent .openHubBtn.open {
    background: #d0d1d5;
}

.private-feed .postContent .activityEndedContent {
    text-align: center;
}

.private-feed .postContent .activityEndIcon {
    width: 78px;
    height: 78px;
    border-radius: 50%;
    color: #FFF;
    display: inline-flex;
    justify-content: center;
}

.private-feed .postContent .activityEndIcon.lost, .private-feed .postContent .activityEndIcon.lost_win_req {
    font-size: 56px;
    align-items: flex-end;
}

.private-feed .postContent .activity-container .starInHandIcon {
    width: 162px;
    height: 104px;
}

.private-feed .postContent .activity-container .flagsSemiFillIcon {
    width: 135px;
    height: 70px;
}

.private-feed .postContent .activity-container .trophySemiFillIcon {
    width: 70px;
    height: 70px;
}

.mob .private-feed .postContent .activity-container .activityEndedContent svg {
    max-height: 50px;
    flex: 0 0 20%;
}

.private-feed .postContent .activityEndedContent .resultSummary, .private-feed .postContent .activityEndedContent .resultFeedback {
    font-size: 18px;
    color: #102F86;
    margin-top: 18px;
    font-weight: bold;
    display: block;
}

.mob .private-feed .postContent .activityEndedContent .resultSummary, .mob .private-feed .postContent .activityEndedContent .resultFeedback {
    text-align: left;
    padding-left: 10px;
    flex: 0 0 80%;
    margin-top: 0;
    font-size: 15px;
}

.mob .private-feed .postContent .activityEndedContent .resultSummary {
    line-height: 18px;
    margin-bottom: 5px;
}

.private-feed .postContent .activityEndedContent .resultFeedback {
    margin-top: 0;
}

.private-feed .postContent .challengeEndSummary {
    display: flex;
    height: 50px;
    width: 392px;
    margin: 24px auto 28px auto;
    justify-content: center;
}

.mob .private-feed .postContent .challengeEndSummary {
    height: auto;
    margin: 10px 0;
    flex: 0 0 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.private-feed .postContent .challengeEndSummary.autoFeedback {
    height: 67px;
    margin: 22px auto 38px auto;
}

.mob .private-feed .activity_def_id_9 .postContent .challengeEndSummary.autoFeedback {
    position: static;
    margin-top: -10px;
}

.private-feed .postContent .summaryTitle {
    width: 34%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #545d78;
    font-size: 14px;
    font-weight: bold;
}

.private-feed .postContent .summaryItem {
    width: 33%;
    text-align: center;
    position: relative;

}

.private-feed .postContent .summaryItem .peakIcon {
    width: 62px;
    height: 59px;
}

.private-feed .postContent .summaryItem:not(.active) .peakIcon #outline {
    fill: #9C9C9C;
}

.private-feed .postContent .summaryItem:not(.active) .peakIcon #flag-bg {
    fill: #C5C5C5;
}

.private-feed .postContent .autoFeedback .summaryItem {
    flex: 1;
}

.private-feed .postContent .summaryLabel {
    font-size: 11px;
    color: #102F86;
    font-weight: bold;
    text-transform: uppercase;
}

.private-feed .postContent .active .summaryLabel {
    color: #5CE09C;
}

.private-feed .postContent .summaryValue {
    font-size: 25px;
    color: #102F86;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 4px;
}

.mob .private-feed .postContent .summaryValue {
    font-size: 22px;
}

.private-feed .postContent .summaryValue.completed {
    font-size: 18px;
    color: #d87b74;
    line-height: 42px;
}

.private-feed .postContent .pointsIcon {
    margin-left: 5px;
}

.private-feed .postContent .summaryValue.points svg {
    margin: 0 5px 0 0;
    width: 22px;
    position: relative;
    top: 2px;
}

.private-feed .rc-slider-disabled {
    background: transparent;
}

.private-feed .rc-slider-step, .private-feed .rc-slider-rail {
    height: 21px;
}

.private-feed .rc-slider-rail {
    background: unset;
    border-left: 3px solid #F2F2F2;
    border-top: 3px solid #F2F2F2;
    border-bottom: 3px solid #F2F2F2;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.private-feed .rc-slider-handle-container {
    position: absolute;
    margin-left: -9px;
    margin-top: -8px;
    width: 18px;
    height: 38px;
    outline: unset;
}

.private-feed .rc-slider-handle-container .white-rows {
    position: absolute;
    display: flex;
    padding: 9px 0;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    width: 100%;
    align-items: center;
}

.private-feed .rc-slider-handle-container .white-rows .white-row {
    background-color: #FFFFFF;
    height: 2px;
    width: 10px;
    z-index: 5;
}

.private-feed .rc-slider-handle {
    position: relative;
    margin: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    border-radius: 4px;
    background-color: #39DD8F;
    outline: unset;
    border: unset;
}

.private-feed .rc-slider-handle:hover {
    border: unset;
    outline: unset;
}

.private-feed .rc-slider-handle.disabled {
    z-index: 2;
}

.private-feed .rc-slider-handle-container:after {
    position: absolute;
    bottom: -8px;
    left: 0;
    border-top: 20px solid #39DD8F;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    display: block;
    content: '';
}

.private-feed .rc-slider-handle-label {
    position: absolute;
    width: 100px;
    left: -40px;
    font-size: 14px;
    line-height: 13px;
    top: -50px;
    color: #2A1566;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
}

.private-feed .progressRow .content {
    bottom: -50px;
}

.private-feed .rc-slider-handle-label .value,
.private-feed .progressRow .content .value {
    font-size: 15px;
    padding-top: 6px;
}

.private-feed .rc-slider-handle-label .value strong,
.private-feed .progressRow .content .value strong {
    padding-right: 5px;
}

.private-feed .progressRow .content .pointsIcon,
.private-feed .rc-slider-handle-label .pointsIcon {
    width: 14px;
    height: 14px;
}

.private-feed .progressRow .content .pointsIcon #trophy,
.private-feed .rc-slider-handle-label .pointsIcon #trophy {
    fill: #FA2D84;
    stroke: #FA2D84;
}

.private-feed .personalChallengeContainer {
    position: relative;
    padding-top: 45px;
    margin-bottom: 100px;
    display: flex;
    justify-content: center;
}

.private-feed .personalChallengeContainer.with-slider {
    width: 350px;
}

.mob .private-feed .personalChallengeContainer.with-slider {
    width: 70%;
    margin-bottom: 48px;
    margin-left: 27px;
}

.mob .private-feed .personalChallengeContainer:not(.with-slider) {
    padding-top: 20px;
    margin-bottom: 0;
}

.private-feed .activityInProgressContent .personalChallengeContainer {
    width: auto;
    margin: 0 30px 0 30px;
    top: -22px;
}

.private-feed .personalChallengeContainer .sliderDummy {
    position: absolute;
    height: 21px;
    top: 50px;
    width: 32px;
    right: -31px;
    background: unset;
    border-top: 3px solid #F2F2F2;
    border-bottom: 3px solid #F2F2F2;
}

.mob .private-feed .personalChallengeContainer .sliderDummy {
    width: 17px;
    right: -16px;
}

.private-feed .personalChallengeContainer .marks {
    position: absolute;
    bottom: -70px;
    left: 0;
    width: 100%;
    height: 60px;
}

.mob .private-feed .personalChallengeContainer .marks {
    height: 25px;
    bottom: -60px;
}

.private-feed .personalChallengeContainer .mark {
    position: absolute;
    bottom: 0;
    background: linear-gradient(to top, rgba(224, 230, 235, 1) 0%, rgba(224, 230, 235, 0) 100%);
    height: 100%;
    width: 1px;
    font-size: 0;
}

.private-feed .personalChallengeContainer .mark:empty {
    height: 50%;
}

.private-feed .personalChallengeContainer .markText {
    position: absolute;
    top: 100%;
    width: 40px;
    text-align: center;
    left: -20px;
    font-size: 14px;
    color: #39257A;
    padding-top: 7px;
}

.private-feed .personalChallengeContainer .winReqValMark, .private-feed .personalChallengeContainer .leadsMark {
    position: absolute;
    bottom: -13px;
    margin-left: -12px;
    width: 24px;
    height: 24px;
    border: 3px solid #FFFFFF;
    background-color: #6724C6;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    border-radius: 50%;
    z-index: 1;
}

.private-feed .personalChallengeContainer .leadsMark {
    background: #414b6b;
    z-index: 3;
}

.private-feed .personalChallengeContainer .winReqValMark .label, .private-feed .personalChallengeContainer .winReqValMark .value {
    top: 26px;
    font-size: 14px;
    color: #39257A;
    font-weight: bold;
    width: 100px;
    text-align: center;
    position: absolute;
    left: -42px;
}

.private-feed .personalChallengeContainer .winReqValMark .value {
    top: 34px;
    font-weight: bold;
}

.private-feed .personalChallengeContainer .leadsRail {
    position: absolute;
    bottom: -9px;
    left: 0;
    border-radius: 6px;
    background: #6724C6;
    height: 15px;
    margin: 0 3px;
}

.private-feed .personalChallengeContainer .leadsRail.ok {
    background: #ffa894;
}

.private-feed .personalChallengeContainer .leadsRail.good {
    background: #75d798;
}

.private-feed .personalChallengeContainer .goBtn-inner-personal,
.private-feed .personalChallengeContainer .goBtn-inner {
    width: 63px;
    height: 63px;
    box-shadow: 0 0 7px rgba(255, 255, 255, 0.27);
    background: #27DA85;
    color: #FFF;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 24px;
    text-transform: uppercase;
    border-radius: 50%;
}

.private-feed .personalChallengeContainer .goBtn-outer-personal.disabled,
.private-feed .personalChallengeContainer .goBtn-outer.disabled {
    cursor: not-allowed;
    opacity: 0.65;
    z-index: 1;
}

.private-feed .personalChallengeContainer .btn:disabled {
    opacity: unset;
    cursor: unset;
}

.private-feed .personalChallengeContainer .goBtn-outer,
.private-feed .personalChallengeContainer .goBtn-outer-personal {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 81px;
    height: 81px;
    background-color: #27da85;
    box-shadow: 0 0 12px rgba(94, 63, 186, .1);
}

.mob .private-feed .personalChallengeContainer .goBtn-outer,
.mob .private-feed .personalChallengeContainer .goBtn-outer-personal {
    width: 55px;
    height: 55px;
}

.private-feed .personalChallengeContainer .goBtn-outer {
    position: absolute;
    right: -101px;
    top: 17px;
    z-index: 1;
}

.mob .private-feed .personalChallengeContainer .goBtn-outer {
    right: -76px;
    top: 33px;
}

.private-feed .postToHubSeparator {
    background: #eeeff0;
    border: 1px solid #e6e6e6;
    height: 7px;
    width: 100%;
}

.mob .private-feed .post .viewDetailsButton {
    background: none;
    border: 0;
    padding: 12px 0;
    box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.15);
    font-weight: bold;
    font-size: 15px;
    color: rgba(127, 46, 219, 0.85);
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
}

.mob .private-feed .post .viewDetailsButton:after {
    content: '';
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 8px solid;
    display: inline-block;
    margin-left: 10px;
    position: relative;
    top: 2px;
}

.private-feed .activityHub {
    background: #FFF;
    width: 100%;
    display: flex;
    min-height: 400px;
}

.private-feed .activityHub .participantsArea {
    width: 100%;
    padding: 11px 0 25px 8px;
    display: flex;
    flex-direction: column;
}

.mob .private-feed .activityHub .participantsArea {
    padding-bottom: 0;
}

.private-feed .postContent .hidden {
    display: none;
}

.private-feed .activityHub .participantsArea .groupByFilterRow {
    height: 51px;
    display: flex;
    align-items: center;
    padding-left: 6px;
    padding-bottom: 5px;
}

.private-feed .activityHub .participantsArea .groupByFilterRow .filterLabel {
    width: 150px;
    padding-left: 25px;
    font-size: 14px;
}

.private-feed .activityHub .participantsArea .groupByFilterRow .filterLink {
    color: #2a1566;
    padding: 5px 17px;
    text-decoration: unset;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
}

.private-feed .activityHub .participantsArea .groupByFilterRow .filterLink.active {
    border: 2px solid #6724C6;
    border-radius: 25px;
}

.private-feed .activityHub .participantsList.single-group {
    padding: 0 70px;
}

.private-feed .activityHub .participantsList .mainRow {
    display: flex;
    align-items: center;
    padding-top: 8px;
}

.private-feed .activityHub .participantsList:not(.single-group) .mainRow .border-dotted {
    display: flex;
    align-items: center;
    flex: 20;
    border-bottom: 1px dotted #D9D6E0;
}

.private-feed .activityHub .participantsList .participantsRow .fire-icon {
    font-size: 27px;
    margin-right: 10px;
    background: linear-gradient(to top, #E10000 3%, #FF3000 25%, #FFB400 92%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .private-feed .activityHub .participantsList .participantsRow .fire-icon {
        background: none;
        color: #FFB400;
    }
}

.private-feed .activityHub .participantsList .participantsRow .trophyFullIcon {
    height: 27px;
    margin-right: 10px;
    position: relative;
    top: 3px;
}

.private-feed .activityHub .participantsList .participantsRow .trophyFullIcon #cup {
    fill: url(#MyGradient);
    stroke: url(#MyGradient);
}

.private-feed .activityHub .participantsList .participantsRow .pos {
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 16px;
    color: #414b6b;
    padding-right: 11px;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    margin-top: -36px;
}

.private-feed .activityHub .participantsList .participantsRow .mainRow .border-dotted {
    flex-direction: column;
    flex: 7;
    padding-bottom: 14px;
}

.private-feed .activityHub .participantsList .participantsRow.user .mainRow .border-dotted {
    flex-grow: 20;
}

.private-feed .activityHub .participantsList .participantsRow .participants {
    margin-right: 16px;
    display: flex;
}

.private-feed .activityHub .participantsList .participantsRow .participants.checked {
    border: 2px solid #33D085;
}

.private-feed .activityHub .participantsList.single-group .participantsRow .participants {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 26px;
}

.private-feed .activityHub .participantsList .participant {
    position: relative;
    display: inline-block;
    margin-left: -8px
}

.private-feed .activityHub .participantsList .participant.teamParticipant {
    display: inline-flex;
    align-items: center;
    text-align: left;
}

.private-feed .activityHub .participantsList .participant:first-child {
    margin-left: 0;
}

.private-feed .activityHub .participantsList .checked-in {
    font-size: 10px;
    white-space: nowrap;
    display: block;
}

.private-feed .activityHub .participantsList .participant img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.private-feed .activityHub .participantsList .participant .checkedInIcon {
    width: 18px;
    height: 18px;
    font-size: 11px;
    color: #FFF;
    border-radius: 50%;
    overflow: hidden;
    background: #75d798;
    display: block;
    padding: 4px 0 0 0;
    letter-spacing: 2px;
    position: absolute;
    bottom: -8px;
}

.private-feed .activityHub .participantsList .participant .teamName {
    color: #2A1566;
    margin-left: 10px;
    font-size: 16px;
}

.private-feed .activityHub .participantsList .participants .popOverLinkContainer, .private-feed .postContent .teamParticipants .popOverLinkContainer {
    height: 38px;
    width: 38px;
    border: 2px solid #3b277b;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 7px 7px 7px 0;
}

.mob .private-feed .postContent .teamParticipants .popOverLinkContainer {
    margin: 7px;
}

.mob .private-feed .activityHub .participantsList .participants .popOverLinkContainer {
    margin: 4px;
}

.private-feed .activityHub .popOverLink:hover {
    text-decoration: unset;
}

.private-feed .activityHub .participantsList .participants .popOverLink, .private-feed .postContent .teamParticipants .popOverLink {
    color: #3b277b;
    font-size: 17px;
    font-weight: bold;
    text-decoration: unset;
}

.private-feed .activityHub .participantsList .leads {
    color: #2A1566;
    font-weight: bold;
    font-size: 18px;
}

.private-feed .activityHub .participantsList .leads .metricName {
    font-size: 16px;
    margin-left: 10px;
}

.private-feed .activityHub .participantsList.single-group .participantsRow.team .progressRow {
    margin-top: 16px;
    justify-content: center;
}

.private-feed .activityHub .participantsList .progressRow {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.private-feed .activityHub .participantsList .progressBar {
    width: 308px;
    height: 14px;
    background: #FFFFFF;
    border-radius: 14px;
    position: relative;
    border: 2px solid #e3e5ea;
}

.mob .private-feed .activityHub .participantsList .progressBar {
    width: 100%;
}

.private-feed .activityHub .participantsList .progressFill {
    height: 100%;
    max-width: 100%;
    background: #27DA85;
    border-radius: 14px;
    position: absolute;
    top: 0;
    left: 0;
}

.private-feed .activityHub .participantsList .progressPercent {
    font-size: 15px;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    color: #3b277b;
    margin-left: 13px;
}

.private-feed .activityHub .participantsList .reachWinReqTime {
    color: #3b277b;
    text-align: left;
    width: 100%;
    margin-bottom: -5px;
    padding-left: 3px;
    font-size: 13px;
}

.private-feed .activityHub .participantsRow.team .border-dotted {
    align-items: flex-start;
}