.enerjoyApp [class*="page_commissions_wizard"] {
    height: 100%;
}

.enerjoyApp [class*="page_commissions_wizard"] .main {
    height: calc(100% - 74px);
    padding: 0;
}

.enerjoyApp [class*="page_commissions_wizard"] .appContent {
    max-width: 100%;
    height: 100%;
    background: transparent;
}

.commission-wizard-container {
    height: 100%;
    padding: 63px 40px 15px 15px;
    display: flex;
    position: relative;
}

.commission-wizard-container .wizardTitleArea {
    position: absolute;
    top: 10px;
    left: 0;
    background: #FFF;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.05);
    font-size: 1.5em;
    padding: 5px 20px;
    border-radius: 0 21px 21px 0;
}

.commission-wizard .mainAreaContainer {
    flex: 1 1;
    margin-right: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.commission-wizard .actionsBar {
    flex: 0;
    background: #FFF;
    margin-top: 20px;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: row-reverse;
    padding: 15px;
    align-items: center;
}

.commission-wizard .actionsBar button,
.commission-wizard .actionsBar button:hover {
    min-width: 120px;
    padding: 0 15px;
}

.commission-wizard .actionsBar .saveButton, .commission-wizard .actionsBar .cancelButton,
.commission-wizard .actionsBar .saveButton:hover, .commission-wizard .actionsBar .cancelButton:hover {
    margin-left: 15px;
    min-width: 130px;
    width: auto;
}

.commission-wizard .actionsBar .sep {
    height: 100%;
    width: 1px;
    border-left: 1px solid #DDD;
    margin-left: 15px;
}

.commission-wizard .actionsBar .messagesArea {
    flex: 1 1;
    text-align: right;
}

.commission-wizard .actionsBar .messagesArea .unsavedChanges {
    font-weight: bold;
}

.commission-wizard .actionsBar .messagesArea .numErrorsMsg {
    color: #e53838;
    font-weight: bold;
}


.commission-wizard .sideBar {
    background: #FFF;
    flex: 250px 0 0;
    height: 100%;
    padding: 15px 0 0 0;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
}

.commission-wizard .removeComponentDropZone {
    margin: 0 15px;
    border: 1px dashed;
    text-transform: capitalize;
    border-radius: 0;
    padding: 30px;
    text-align: center;
}

.commission-wizard .removeComponentDropZone:hover {
    background: rgba(0, 0, 0, 0.25);
}

.commission-wizard .addButton {
    border: 1px dashed;
    text-transform: capitalize;
    border-radius: 0;
}

.commission-wizard .addButton.changed {
    box-shadow: 0 0 5px 0 rgba(255, 122, 0, 0.8);
}

.commission-wizard .addButton span {
    white-space: nowrap;
}

.commission-wizard .sideBar .addButton {
    margin: 20px 10px;
}

.commission-wizard .addButton .MuiButton-label:before {
    content: '+';
    margin-right: 5px;
    display: block;
}

.commission-wizard-menu.commission-wizard-sidebar {
    min-width: 220px;
}

.commission-wizard .sideBar .erjScroll .track-vertical {
    right: -5px;
}

.commission-wizard .sideBar .componentItems, .commission-wizard .sideBar .mainComponent {
    padding: 0 15px;
}

.commission-wizard .sideBar .componentItems {
    padding-top: 5px;
}

.commission-wizard .sideBarComponent {
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid #DDD;
    border-radius: 7px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    padding: 5px 7px;
    padding-right: 3px;
}

.commission-wizard .sideBarComponent.selected {
    border-color: #9824c6;
    box-shadow: 0 3px 7px 0 rgba(152, 36, 198, 0.2);
}

.commission-wizard .sideBarComponent.changed {
    box-shadow: 0 3px 7px 0 rgba(255, 122, 0, 0.4);
}

.commission-wizard .sideBarComponent .editButton {
    padding: 10px;
}

.commission-wizard .sideBarComponent .editButton svg {
    font-size: 1.4rem;
}

.commission-wizard .mainComponent .sideBarComponent {
    background: #9824c6;
    border-color: #FFF;
    color: #FFF;
}

.commission-wizard .mainComponent .sideBarComponent .radioBtn svg {
    fill: #FFF;
}

.commission-wizard .sideBar .sep {
    height: 1px;
    border-bottom: 1px solid #DDD;
    margin: 0 15px;
}

.commission-wizard .sideBarComponent .dragHandle {
    cursor: move;
}

.commission-wizard .sideBarComponent .details {
    padding-left: 10px;
    flex-grow: 1;
    overflow: hidden;
}

.commission-wizard .sideBarComponent .title {
    font-weight: bold;
    display: flex;
    align-items: center;
}

.commission-wizard .sideBarComponent .description {
    font-size: 0.9em;
    margin-top: 5px;
    opacity: 0.8;
}

.commission-wizard .componentLabel, .commission-wizard-menu .componentLabel {
    display: flex;
    align-items: center;
    overflow: hidden;
}

.commission-wizard button.componentLabel {
    border: 0;
    background: none;
    font-weight: bold;
}

.commission-wizard button.componentLabel:hover {
    color: #9824c6;
}

.commission-wizard .componentLabel .label {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.commission-wizard .componentIcon, .commission-wizard-menu .componentIcon {
    display: flex;
    font-size: 1em;
    background: #33363b;
    color: #FFF;
    line-height: 1em;
    align-items: center;
    width: 1.3em;
    height: 1.3em;
    justify-content: center;
    border-radius: 0.2em;
    margin-right: 0.5em;
}

.commission-wizard .mainComponent .componentIcon {
    background: #FFF;
    color: #9824c6;
}

.commission-wizard .componentIcon svg, .commission-wizard-menu .componentIcon svg {
    font-size: 1em;
}

.commission-wizard .tierTableSepArrow svg {
    font-size: 1.2em;
    margin: 0 10px;
    position: relative;
    top: 2px;
}

.commission-wizard .editComponentModal .closeIcon {
    font-size: 30px;
    width: 45px;
    float: right;
    cursor: pointer;
}

.commission-wizard .editComponentModal .popup-buttons {
    text-align: right;
}

.commission-wizard .editComponentModal .btn-cancel-popup, .commission-wizard .editComponentModal .btn-save-popup {
    margin-top: 1px;
    margin-left: 15px;
    height: 40px;
}

.commission-wizard .editComponentModal #modal-title span {
    font-family: "OpenSans", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 18px;
    display: block;
    margin-bottom: 20px;
}

.commission-wizard .editComponentModal .textbox-title {
    font-size: 16px;
    font-weight: bold;
    color: #46494d;
}

.commission-wizard .editComponentModal .component-name-input {
    font-family: "OpenSans", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 14px !important;
    color: #33363b !important;
}

.commission-wizard .editComponentModal .paper {
    padding: 0;
    outline: none;
}

.commission-wizard .editComponentModal .textbox-title {
    padding-bottom: 5px;
}

.commission-wizard .editComponentModal .contentWrapper {
    padding: 32px;
    padding-top: 20px;
}

.commission-wizard .editComponentModal .closeIcon {
    position: absolute;
    top: 13px;
    right: 2px;
    color: #fff;
    font-size: 30px;
}

.commission-wizard .editComponentModal #modal-title {
    background-color: #9824c6;
    color: #ffffff;
    font-weight: 300;
    width: 100%;
    height: 60px;
    padding-top: 20px;
    padding-left: 17px;
    display: block;
}

.commission-wizard .editComponentModal input {
    height: 40px;
}

.commission-wizard .editComponentModal .form-row {
    margin: 0 0 30px 0;
}

.commission-wizard .editComponentModal .ErjTable .tableCell {
    padding: 4px 12px
}

.commission-wizard .editComponentModal .ErjTable .tableCell:first-child {
    width: 60%;
    text-align: left;
}

.commission-wizard .editComponentModal .ErjTable .tableCell:nth-child(2) {
    width: 30%;
}

.commission-wizard .editComponentModal .ErjTable .tableCell:last-child {
    padding: 4px 0;
}

.commission-wizard .editComponentModal .range-sep {
    margin: 0 5px;
    font-size: 20px;
    font-weight: bold;
}

.commission-wizard .editComponentModal .tier-range .erj-number-input {
    display: inline-flex;
    width: 45%;
}

.commission-wizard .editComponentModal .erj-number-input input {
    color: #495057;
}

.commission-wizard .editComponentModal .erj-number-input input::placeholder {
    color: #97a2ad;
}

.commission-wizard .form-control.hasError {
    border-color: red;
}

.commission-wizard .error {
    color: red;
}

.commission-wizard .addTierIcon {
    margin-right: 7px;
}

.commission-wizard .editComponentModal .buttons.popup-buttons {
    margin-top: 8px;
}

.commission-wizard .editComponentModal label {
    margin-bottom: 5px
}

.commission-wizard .editComponentModal input[type="text"] {
    margin-top: 0;
}


.commission-wizard .designer {
    flex: 1 1;
    overflow: hidden;
    position: relative;
    border-radius: 7px;
    border: 1px solid #9824c6;
    box-shadow: 0 0 7px 0 rgba(152, 36, 198, 0.2);
}

.commission-wizard .backButton {
    margin-left: -20px;
    margin-top: -10px;
    position: relative;
    top: 5px;
    border-right: 1px solid #CCC;
    margin-right: 15px;
    border-radius: 0;
}

.commission-wizard .designerTitle {
    z-index: 2;
    padding: 0 10px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #9824c8;
    font-weight: bold;
    font-size: 1.1em;
    border-radius: 7px;
    border-top: 0;
    color: #FFF;
    line-height: 2.5em;
}

.commission-wizard .designerTitle .editButton {
    padding: 6px;
    position: relative;
    right: -5px;
    top: -1px;
    margin-left: 5px;
}

.commission-wizard .designerTitle .editButton svg {
    font-size: 1em;
    color: #FFF;
    width: 0.9em;
    height: 0.9em;
}

.commission-wizard .designer .parentComponentsMenuBtn {
    position: absolute;
    top: -1px;
    right: -1px;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid #DDD;
    z-index: 2;
    background: #FFF;
    color: #33363b;
    border-radius: 0 7px 0 7px;
    min-width: 50px;
    padding: 4px 10px;
}

.commission-wizard .designer .parentComponentsMenuBtn:hover {
    color: #9824c8;
}

.commission-wizard .designer .parentComponentsMenuIcon {
    position: relative;
    top: 2px;
}

.commission-wizard .designer .tools {
    position: absolute;
    bottom: 30px;
    left: 30px;
}

.commission-wizard .designer .diagram {
    padding: 40px;
    display: flex;
    flex-wrap: nowrap;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    align-items: flex-start;
    cursor: move;
}

.commission-wizard .designer .step {
    background: #FFF;
    min-width: 250px;
    min-height: 250px;
    position: relative;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    padding: 25px 15px 15px 15px;
}

.commission-wizard .designer .step.changed {
    box-shadow: 0 3px 7px 0 rgba(255, 128, 0, 0.5);
}

.commission-wizard .designer .step .stepTitle {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.75);
    padding: 5px 15px;
    border-radius: 7px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
    font-weight: bold;
    color: rgb(152 36 198);
    white-space: nowrap;
}

.commission-wizard .designer .step .removeStepBtn, .commission-wizard .designer .step .addPrevStepBtn, .commission-wizard .designer .step .moveToSavedComponentBtn {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    background: rgba(255, 255, 255, 0.75);
    padding: 5px;
    border-radius: 50%;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.05);
    font-size: 1em;
}

.commission-wizard .designer .step .addPrevStepBtn {
    right: auto;
    left: 0;
    transform: translate(-50%, -50%);
}

.commission-wizard .designer .step .moveToSavedComponentBtn {
    top: auto;
    bottom: 0;
    transform: translate(0, 0);
    background: #FFF;
    border-radius: 0;
    z-index: 2;
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
}

.commission-wizard .designer .step .removeStepBtn svg, .commission-wizard .designer .step .addPrevStepBtn svg, .commission-wizard .designer .step .moveToSavedComponentBtn svg {
    width: 0.9em;
    height: 0.9em;
}

.commission-wizard .designer .stepContainer {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.commission-wizard .designer .stepContainer {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.commission-wizard .designer .step .function {
    background: rgba(170, 170, 170, 0.15);
    border: 1px dashed rgba(170, 170, 170, 0.75);
    padding: 10px;
    position: relative;
    margin-top: 20px;
    min-width: 200px;
}

.commission-wizard .designer .step .function.changed {
    box-shadow: 0 0 3px 1px rgba(255, 128, 0, 0.75);
}

.commission-wizard .designer .step .function .removeFuncBtn {
    position: absolute;
    top: 0;
    right: -1px;
    transform: translateY(-100%);
    background: rgba(170, 170, 170, 0.3);
    padding: 4px 6px;
    border: 1px dashed rgba(170, 170, 170, 0.75);
    border-radius: 0;
    font-size: 1em;
}

.commission-wizard .designer .step .function .removeFuncBtn svg {
    width: 0.7em;
    height: 0.7em;
}

.commission-wizard .designer .step .function .name {
    position: absolute;
    top: 0;
    left: -1px;
    transform: translateY(-100%);
    background: rgba(170, 170, 170, 0.3);
    padding: 0 5px;
    border: 1px dashed rgba(170, 170, 170, 0.75);
    font-weight: bold;
    font-size: 0.9em;
}

.commission-wizard .designer .step .function .args {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.commission-wizard .designer .step .function .args .sep {
    font-size: 0.85em;
    background: rgba(170, 170, 170, 0.75);
    font-weight: bold;
    color: rgba(0, 0, 0, 0.65);
    margin: 3px 0;
    padding: 0 5px;
}

.commission-wizard .designer .stepContainer .argValue {
    display: flex;
    background: #FFF;
    border-radius: 15px;
    box-shadow: 0 0 3px 1px #aaa;
    padding: 0 0 0 10px;
    align-items: center;
    overflow: hidden;
}

.commission-wizard .designer .stepContainer .argValue.changed {
    box-shadow: 0 0 3px 1px rgba(255, 128, 0, 0.75);
}

.commission-wizard .designer .stepContainer .argName {
    font-weight: bold;
    display: flex;
}

.commission-wizard .designer .stepContainer .removeArgBtn {
    font-size: 1em;
    padding: 6px 6px 6px 4px;
    background: #EEE;
    border-radius: 0;
    margin-left: 10px;
}

.commission-wizard .designer .stepContainer .removeArgBtn svg {
    width: 0.7em;
    height: 0.7em;
}

.commission-wizard .designer .conditionOutSteps {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    padding-left: 100px;
    position: relative;
}

.commission-wizard .designer .conditionOutSteps .swapConditionStepsBtn {
    position: absolute;
    left: 44px;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0 0 3px 1px #aaa;
    padding: 6px;
    background: #FFF;
}

.commission-wizard .designer .conditionOutSteps .stepContainer {
    flex: 50% 0 0;
    position: relative;
}

.commission-wizard .designer .conditionOutStepLabel {
    position: absolute;
    top: 50%;
    left: -20px;
    transform: translateY(-175%);
    font-weight: bold;
    color: #609f60;
}

.commission-wizard .designer .conditionOutStepLabel.step_false {
    color: #9f6060;
}

.commission-wizard .designer .conditionOutSteps .stepContainer:first-child {
    margin-bottom: 100px;
}

.commission-wizard .sideBarComponent.hasErrors,
.commission-wizard .designer .step.hasError,
.commission-wizard .designer .step .function .args.hasError,
.commission-wizard .conditionOutSteps > .hasError .addButton {
    box-shadow: 0 0 4px 1px red;
}


.commissionModal .addColumnButton {
    min-width: 26px;
    border: 1px solid hsl(265deg 69% 76%);
    padding: 0;
    margin-right: 5px;
    box-shadow: 0 0 5px hsl(265deg 69% 76%);
    position: absolute;
    top: 10px;
    left: 163px;
    height: 26px;
    background: #6724c6;
    z-index: 2;
}

.commissionModal .addColumnButton .title {
    font-weight: bold;
    align-items: center;
    font-size: 1.2em;
}

.commissionModal .addColumnButton svg {
    display: none;
}

.commissionModal .componentLabel {
    display: flex;
    border: 0;
    padding: 0;
    color: inherit;
    background: inherit;
    cursor: text;
    justify-content: center;
    width: 100%;
    align-items: center;
}

.commissionModal .componentLabel .label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 150px;
}

.commissionModal .componentLabel .componentIcon, .commissionModal .componentLabel .componentIcon svg {
    font-size: 1em;
    line-height: 1em;
}

.commissionModal .componentLabel .componentIcon {
    margin-right: 5px;
}

.commissionModal .removeDisplayColumnBtn {
    color: rgba(255, 255, 255, 0);
    position: absolute;
    top: 50%;
    right: 0;
    padding: 5px;
    transition: color 0.2s;
    transform: translateY(-50%);
}

.commissionModal .summaryHeader > .title:hover .removeDisplayColumnBtn {
    color: rgba(255, 255, 255, 0.75);
}

.commissionModal .editComponentNumberBtn {
    color: rgba(103, 36, 198, 0);
    position: absolute;
    top: 50%;
    right: 0;
    padding: 5px;
    transform: translateY(-50%);
}

.commissionModal .userRow:hover .editComponentNumberBtn {
    color: rgba(103, 36, 198, 1);
}

.commissionModal .userRow .editableCompoent {
    margin: 0 -10px;
}

.commissionModal .userRow .editableCompoent .form-control {
    width: 50%;
    display: inline-block;
    height: 40px;
    margin: 0 4px;
    padding: 0 10px;
}

.commissionModal .userRow .editableCompoent button {
    font-size: 1em;
    padding: 6px;
}

.commissionModal .userRow .saveComponentNumberBtn {
    color: #6724c6;
}