.enerjoyApp header {
    color: rgba(255, 255, 255, 1);
    background-color: #091d55;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    height: 74px;
}

.enerjoyApp .mob header {
    height: auto;
}

.enerjoyApp header .container {
    height: 100%;
}

.enerjoyApp header .rightMenu, .enerjoyApp header .leftMenu {
    height: 100%;
    display: flex;
    align-items: center;
}

.enerjoyApp header .leftMenu {
    position: relative;
}

.enerjoyApp header .leftMenu .currentCenterTitle {
    position: absolute;
    bottom: 2px;
    left: 0;
    color: #4ed896;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    width: 360px;
}

.enerjoyApp .mob header .rightMenu {
    width: 100%;
}

header .menuItem {
    display: flex;
    padding: 0 14px;
    text-align: center;
    position: relative;
    cursor: pointer;
    color: #FFF;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

header .menuItem .menuItemLabel {
    flex-direction: inherit;
}

header .menuItem.active:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    background: #4ed896;
    bottom: 0;
    left: 0;
}

.enerjoyApp header .current_time {
    font-size: 15px;
    display: flex;
    align-items: center;
}

.enerjoyApp header .dropdownIcon {
    font-size: 18px;
    position: absolute;
    top: 18px;
    right: -6px;
}

.enerjoyApp .mob header .dropdownIcon {
    top: 15px;
    left: 5px;
}

header .userProfileMenuButtonArea,
header .buttonArea {
    display: flex;
    height: 100%;
}

header .userProfileMenuButton {
    padding: 8px 27px 8px 21px;
    min-width: auto;
    display: inline-block;
    text-transform: none;
}

.mob header .userProfileMenuButton {
    padding-left: 30px;
}

header .userProfileImg {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: block;
    margin: 2px auto 3px auto;
}

header #menu-appbar {
    transform-origin: 0 0 0;
}

header .userProfileMenu {
    margin-top: 6px;
    margin-right: -18px;
    width: 320px;
}

header .userProfileMenu:before {
    z-index: 9999;
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 3px;
    right: 3px;
    box-sizing: border-box;
    border: 6px solid black;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: #ffffff;
    border-bottom-color: #ffffff;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -3px 3px 3px -2.4px rgba(0, 0, 0, 0.33);
}

header .userProfileMenuContainer .userProfileMenu,
header .menuContainer div div {
    box-shadow: -1px 1px 6px 0 rgba(0, 0, 0, 0.33)
}

header .userProfileMenuContainer ul li,
header .menuContainer ul li {
    font-family: "OpenSans", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
    color: #494c50;
    padding: 12px 26px 12px 16px;
}

header .menuContainer ul li.hasAlert:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #e53838;
    position: absolute;
    top: 21px;
    right: 7px;
    border-radius: 50%;
    box-shadow: 0 0 0 2px rgba(229, 56, 56, 0.33);
}

header .userProfileMenuContainer ul li.selectedCenter {
    font-weight: bold;
}

header .userProfileMenuContainer > div,
header .menuContainer > div {
    z-index: 100;
}

header .userProfileMenuContainer ul li:hover,
header .menuContainer ul li:hover {
    background-color: #f3f5f9;
}

header .menuIcon, header a.menuIconLink:link, header a.menuIconLink:active, header a.menuIconLink:hover, header a.menuIconLink:visited,
.headerDropMenu.mob.menuIcon, .headerDropMenu.mob a.menuIconLink:link, .headerDropMenu.mob a.menuIconLink:active, .headerDropMenu.mob a.menuIconLink:hover, .headerDropMenu.mob a.menuIconLink:visited {
    display: inherit;
    color: #FFF;
    text-decoration: none;
    flex-direction: inherit;
    align-items: inherit;
    justify-content: inherit;
}

header .menuIcon, .headerDropMenu.mob .menuIcon {
    font-size: 30px;
    width: 45px;
    height: 34px;
    display: inline-block;
    padding: 0;
}

header .active .menuIcon {
    color: #4ed896;
}

header .menuItem.hasAlert .menuIcon:after, .headerDropMenu.mob .menuItem.hasAlert .menuIcon:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #e53838;
    position: absolute;
    top: 0;
    right: 5px;
    border-radius: 50%;
    box-shadow: 0 0 0 2px rgba(229, 56, 56, 0.33);
}

header .menuItem.hasAlert .menuIcon.ManagerStore-navButton:after, .headerDropMenu.mob .menuItem.hasAlert .menuIcon.ManagerStore-navButton:after {
    right: 5px;
}

header .menuItemLabel {
    display: block;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: normal;
    line-height: normal;
    font-family: "OpenSans", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    position: relative;
}

.menuIcon.notification-navButton {
    font-size: 37px;
    position: relative;
    top: -7px;
}

.menuIcon.notification-navButton .numUnread {
    position: absolute;
    top: 2px;
    right: 5px;
    min-width: 18px;
    height: 18px;
    background: #e53838;
    border-radius: 9px;
    color: #FFF;
    font-family: "OpenSans", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 11px;
    text-align: center;
    line-height: 17px;
    box-shadow: 0 0 0 3px rgba(229, 56, 56, 0.33);
    padding: 0 5px;
}

header .notificationsMenuButtonArea {
    display: flex;
    height: 100%;
    padding: 0 10px;
}

header .notificationsMenuContainer {
}

header #menu-notifications {
    transform-origin: 0 0 0;
}

header .notificationsMenu {
    margin-top: 15px;
    height: 527px;
}

header .notificationsList {
    height: 492px;
}
header .notificationsMenu:before {
    z-index: 9999;
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 3px;
    right: 3px;
    box-sizing: border-box;
    border: 6px solid black;
    border-top-color: transparent;
    border-right-color: transparent;
    border-left-color: #ffffff;
    border-bottom-color: #ffffff;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -3px 3px 3px -2.4px rgba(0, 0, 0, 0.33);
}

header .notificationsMenuContainer > div > div {
    box-shadow: -1px 1px 6px 0 rgba(0, 0, 0, 0.33)
}

header .notificationsMenuContainer > div {
    width: 375px;
    left: 3px !important;
    z-index: 100;
}

header .notificationsMenuContainer ul li:hover {
    background-color: #ebeef5;
}

header .notificationsMenu .notificationsTitle {
    color: #46494d;
    font-size: 20px;
    text-transform: uppercase;
    height: 60px;
    border-bottom: 1px solid #dde1e7;
    padding: 19px 0 0 27px;
}

header .notificationsMenu .notificationsSectionTitle {
    font-size: 18px;
    color: #46494d;
    border-bottom: 1px solid #dde1e7;
    padding: 4px 0 3px 27px;
}

header .notificationsMenu [role="menu"] {
    padding: 0;
}

header .notificationsMenu li {
    font-family: "OpenSans", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
    color: #494c50;
    height: auto;
    padding-left: 0;
    padding-right: 22px;
    white-space: normal;
    display: flex;
    align-items: center;
    background-color: #f5f6fa;
}

header .notificationsMenu li.clicked {
    background-color: #FFF;
}

header .notificationsMenu .notificationRowIcon {
    width: 100px;
    height: auto;
    padding: 10px 20px;
    flex-shrink: 0;
}

header .notificationsMenu .notificationRowIcon img {
    border-radius: 50%;
    width: 100%;
    height: auto;
}

header .notificationsMenu .notificationRowIcon .activity-symbol {
    box-shadow: 0 0 17px 5px rgba(94, 63, 186, 0.09);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

header .notificationsMenu .notificationRowIcon .activity-symbol svg {
    width: 65%;
    max-height: 55%;
}

header .notificationsMenu .notificationRowIcon .activity-symbol .bellIcon {
    width: 50%;
}

header .notificationsMenu .notificationRowIcon .activity-symbol .teamWorkIcon #heads, header .notificationsMenu .notificationRowIcon .activity-symbol .teamWorkIcon #connections {
    fill: #24D17E;
}

header .notificationsMenu .notificationRowIcon .activity-symbol .teamWorkIcon #shirts {
    fill: #39BBD7;
}

header .notificationsMenu .notificationContentArea {
    width: 100%;
}

header .notificationsMenu .notificationTitle {
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    color: #5cbcff;
    margin-bottom: 1px;
    width: 100%;
}

header .notificationsMenu .notificationBody {
    font-size: 12px;
    width: 100%;
}

header .notificationsMenu .notificationTimeAgo {
    font-size: 12px;
    color: #afb3bc;
    margin-top: -1px;
}

header .notificationsMenu .notificationsSettingsRow {
    padding: 0 10px;
    height: 35px;
    display: flex;
    align-items: center;
    border-top: 1px solid #dde1e7;
}

header .notificationsMenu .muteNotifSoundBtn {
    width: auto;
    height: auto;
    padding: 0;
}

header .notificationsMenu .muteNotifSoundBtn svg {
    font-size: inherit;
}

header .notificationsMenu .muteNotifSoundBtn .on {
    fill: #5cbcff;
}

header .work-plan {
    position: relative;
}

header .work-plan .WorkPlanPage {
    position: absolute;
    width: 150px;
    height: 57px;
    right: 0;
}

.menuIcon.store-navButton, .menuIcon.ManagerStore-navButton {
    font-size: 29px
}

.menuIcon.workSchedule-navButton, .menuIcon.weeklyPlan-navButton {
    font-size: 32px;
    line-height: 24px;
}

.menuIcon.reports-navButton {
    font-size: 24px;
    line-height: 28px;
}

.menuIcon.manageChallenges-navButton {
    font-size: 26px
}

.menuIcon.tvSetupPage-navButton {
    font-size: 19px;
    line-height: 27px;
}

.rightMenu .MuiSvgIcon-root {
    font-size: 1.6rem;
}

.enerjoyApp header .daysLeftGraph {
    width: 44px;
    height: 44px;
    position: relative;
    margin: 0 10px 0 0;
    display: inline-block;
    color: #FFF;
    padding: 0;
}

header .daysLeftNum {
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 14px;
    top: 50%;
    margin-top: -11px;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
}

header .progressCircle {
    color: #FFF;
    opacity: 0.75;
    position: relative;
    top: 9px;
    right: 11px;
}

header div.userProfileMenuButtonArea button span:first-child {
    text-transform: capitalize;
    display: block;
    position: relative;
    padding-right: 11px;
    font-size: 13px;
}

.mob header .currentPageTitle {
    flex: 1 1;
    text-align: center;
    padding-right: 87px;
    font-weight: bold;
    font-size: 18px;
}

.headerDropMenu.mob {
    top: 56px;
}

.mob .headerDropMenuBody {
    background: #172E6E;
}

.mob .headerDropMenuBody a, .mob .headerDropMenuBody button, .mob .headerDropMenuBody .menuItem, .mob .headerDropMenuBody li {
    width: 100%;
    color: #FFF;
}

.mob .headerDropMenuBody ul {
    padding: 0;
}

.mob .headerDropMenuBody li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    height: 35px;
}

.mob .headerDropMenuBody .menuIcon {
    display: none;
}