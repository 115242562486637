.enerjoyApp .page_login {
    height: 100%;
}

.enerjoyApp .page_login .main {
    padding: 0;
    height: 100%;
}

.enerjoyApp .page_login .main > .container {
    height: 100%;
    max-width: 100%;
}

.enerjoyApp .page_login .appContent {
    height: 100%;
    max-width: 100%;
    padding: 25px;
    background: transparent;
}

.enerjoyApp .page_login.mob .appContent {
    padding: 0;
}

.enerjoyApp .page_login .footer {
    display: none;
    /*background: transparent;*/
    /*width: 41.5%;*/
    /*text-align: center;*/
    /*z-index: 2;*/
}

.enerjoyApp .loginPage {
    height: 100%;
    display: flex;
    position: relative;
}

.enerjoyApp .mob .loginPage {
    height: auto;
    min-height: 100%;
    align-items: flex-start;
}

.enerjoyApp .loginPage .langChooser {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 150px;
}
/* e.s. kicdev 07/07/2023  see login.js */
.platform {
    position: absolute;
    color:#b39a7d;
    top: 80px;
    right: 30px;
    width: 150px;
}

.enerjoyApp .mob .loginPage .langChooser {
    top: 5px;
    right: 5px;
    z-index: 2;
}

.page_login .formBg {
    height: 100%;
    width: 41.5%;
    background: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.page_login .recaptchaNotice {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 10px;
    opacity: 0.75;
}

.page_login.mob .recaptchaNotice {
    position: relative;
    top: 15px;
    font-size: 0.8em;
    padding: 0;
}

.page_login .background {
    height: 100%;
    background: url("./login_bg.jpg") no-repeat;
    background-size: cover;
    width: 58%;
}

.page_login .welcomeMsg {
    font-size: 48px;
    color: #FFF;
    text-align: center;
    font-weight: bold;
    padding-top: 110px;
    text-transform: uppercase;
    text-shadow: 0 0 5px #999;
}

.page_login .loginForm {
    width: 361px;
}

.enerjoyApp .page_login h1 {
    font-family: "OpenSans", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: bold;
    font-size: 18px;
    color: #33363b;
    margin-bottom: 49px;
}

.page_login .loginForm .form-row {
    padding-bottom: 20px;
}

.page_login .loginForm .form-row.passwordRow {
    padding-bottom: 25px;
}

.page_login .loginForm .form-control {
    margin: 5px 0 0 0;
}

.page_login .loginForm .buttons {
    padding-top: 13px;
    text-align: right;
}

.page_login .loginStep2Form {
    text-align: center;
}

.page_login .qrCode {
    margin-bottom: 40px;
}

@keyframes moveArrowIcon {
    from {top: 0;}
    to {top: -35px;}
}
.requestApproveNotificationMsg {
    position: absolute;
    left: 274px;
    top: 140px;
    font-size: 36px;
    font-weight: bold;
    color: #75d798;
    text-transform: uppercase;
}

.requestApproveNotificationMsg .msg {
    display: inline-block;
}

.requestApproveNotificationMsg .welcome {
    color: #7054CD;
    text-align: center;
    margin-bottom: 15px;
}

.requestApproveNotificationMsg.brws_lang_he {
    left: auto;
    right: 233px;
    direction: rtl;
}
.requestApproveNotificationMsg.blocked {
    color: #D42858;
}

.requestApproveNotificationMsg .arrowUpToLeftIcon {
    position: relative;
    top: 0;
    -webkit-animation: moveArrowIcon 1s infinite alternate;
    animation: moveArrowIcon 0.5s infinite alternate;
    margin-right: 15px;
    height: 100px;
    fill: #75d798;
}

.requestApproveNotificationMsg.brws_lang_he .arrowUpToLeftIcon {
    transform: rotateY(180deg);
    display: inline-block;
    margin-left: 15px;
}

.requestApproveNotificationMsg .crossIcon {
    font-size: 72px;
    display: block;
    float: left;
    margin-right: 25px;
    transform: rotate(45deg);
}

#recaptcha_checkbox_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.page_login.mob .background {
    height: 100%;
    background: url("./login_bg.jpg") no-repeat;
    background-size: cover;
    width: 100%;
    position: absolute;
    z-index: 1;
}

.page_login.mob .welcomeMsg {
    font-size: 28px;
    color: #FFF;
    text-align: center;
    font-weight: bold;
    padding-top: 58px;
    text-transform: uppercase;
    text-shadow: 0 0 5px #999;
}

.page_login.mob .formBg {
    position: relative;
    z-index: 2;
    transform: translateX(-50%);
    top: 0;
    justify-content: flex-start;
    margin-top: 120px;
    height: auto;
    padding: 30px;
    left: 50%;
    width: 90%;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.9);
}

.page_login.mob .form-row {
    margin-left: 10%;
    width: 90%;
    padding-bottom: 5px;
}

.page_login.mob .form-control {
    width: 90%;
}

.page_login.mob .buttons {
    padding-top: 13px;
    padding-right: 13px;
    width: 90%;
    flex-direction: row-reverse;
}