.enerjoyApp .navigationBar {
    padding: 20px 70px;
    height: 50px;
    background: #0c1f52;
    color: #FFF;
    display: flex;
    align-items: center;
    z-index: 1;
    position: relative;
    overflow: hidden;
    justify-content: center;
}


.enerjoyApp .navigationBar.activity_def_id_8 {
    background-color: #703DC2;
}

.enerjoyApp .navigationBar.activity_def_id_13 {
    background-color: #7136C5;
}

.enerjoyApp .navigationBar.activity_def_id_14 {
    background-color: #00A7CB;
}

.enerjoyApp .navigationBar button {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    min-width: 50px;
    background: rgba(255, 255, 255, 0.1);
}

.enerjoyApp .navigationTitle {
    color: white;
    font-weight: bold;
    font-size: 16px;
    text-transform: capitalize;
}
