.employee-profile .target {
    cursor: pointer;
}

.employee-profile-popup {
    position: fixed;
    bottom: 0;
    z-index: 3;
    left: calc(50% - 610px);
    width: 396px;
    background-color: #FFFFFF;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
    max-height: calc(100% - 97px);
    padding-bottom: 10px;
}

.employee-profile-popup:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 5px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.25), transparent);
}

.employee-profile-popup .closeButton {
    height: 30px;
    width: 30px;
    padding: 0;
    color: #6724c6;
    position: absolute;
    top: 10px;
    right: 15px;
}

.employee-profile-popup .closeButton span {
    font-size: 30px;
}

.employee-profile-popup .user-row {
    display: flex;
    height: 133px;
    padding: 0 25px;
    align-items: center;
}

.employee-profile-popup .user-row .user-image {
    width: 79px;
    height: 79px;
    border-radius: 50%;
}

.employee-profile-popup .user-row .details {
    padding-left: 18px;
    line-height: 40px;
}

.employee-profile-popup .user-row .user-name {
    font-size: 20px;
}

.employee-profile-popup .user-row .user-points {
    display: flex;
    font-size: 16px;
}

.employee-profile-popup .user-row .pointsIcon {
    width: 22px;
    height: auto;
    margin-right: 10px;
}

.employee-profile-popup .tabs {
    border-top: 1px solid #dde1e7;
}

.employee-profile-popup .tabs button:first-child, .employee-profile-popup .tabs button:first-child:focus {
    border-right: 1px solid #dde1e7;
}

.employee-profile-popup .top-area {
    border-bottom: 1px solid #dde1e7;
}

.employee-profile-popup .erjMenuWrapper {
    display: block;
    width: 100%;
    background: #F4F4F4;
}

.enerjoyApp .employee-profile-popup .erjMenu-button {
    width: 100%;
    text-align: center;
    height: 36px;
    border-bottom: 1px solid #dde1e7;
}

.employee-profile-popup .erjMenuPopper {
    width: 100%;
}

.employee-profile-popup .erjMenuPopper [role="menu"] {
    padding: 0;
}

.employee-profile-popup .erjMenuPopper [role="menuitem"] {
    text-align: center;
    display: block;
}

.employee-profile-popup .metric-row {
    display: flex;
    border-bottom: 1px solid #dde1e7;
    text-align: left;
}

.employee-profile-popup .metric-row .metric-title {
    display: flex;
    flex: 160px 0 0;
    align-items: center;
    text-align: left;
    padding-left: 16px;
}

.employee-profile-popup .metric-row .leads {
    color: #868d9b;
}

.employee-profile-popup .metric-row .progressBar {
    margin-top: 2px;
    align-items: center;
    display: flex;
    position: relative;
    width: 164px;
    height: 4px;
    background-color: #BCC7D2;
    margin-right: 4px;
}

.employee-profile-popup .metric-row .metric-data.you .progressBar-container {
    display: flex;
    align-items: center;
    margin-top: -7px;
}

.employee-profile-popup .metric-row .currentProgress {
    background-color: #9824c6;
    height: inherit;
}

.employee-profile-popup .metric-row .leadsIndicator {
    background-color: #9824c6;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.employee-profile-popup .metric-row .metric-data-container {
    flex: 1 1;
}

.employee-profile-popup .metric-row .metric-data {
    height: 62px;
    background-color: #fcf6fe;
    padding: 6px 0 0 17px;
}

.employee-profile-popup .metric-row .metric-data.you {
    background-color: hsla(283, 61%, 94%, 1);
    color: #9824c6;
}

.employee-profile-popup .insightsTab .sectionTitle {
    color: rgba(123, 39, 218, 0.8);
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    padding-top: 8px;
}

.employee-profile-popup .insightsTab .summary {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #dde1e7;
}

.employee-profile-popup .insightsTab .summaryItem {
    flex: 50% 1 0;
    text-align: center;
    padding: 10px 0;
}

.employee-profile-popup .insightsTab .summaryItem .number {
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    color: #102F86;
    font-size: 24px;
}

.employee-profile-popup .insightsTab .summaryItem .label {
    font-weight: bold;
    color: rgba(16, 47, 134, 0.66);
    font-size: 14px;
}

.employee-profile-popup .insightsTab .orders {
    display: flex;
    padding: 10px;
}

.employee-profile-popup .insightsTab .order {
    flex: 33% 0 0;
    text-align: center;
    padding: 0 10px;
}

.employee-profile-popup .insightsTab .product {
    width: 105px;
    height: 105px;
    background-size: cover;
    border-radius: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-size: 16px;
    padding: 5px 10px;
    color: #FFF;
}

.employee-profile-popup .insightsTab .orderTime {
    font-weight: bold;
    padding-top: 3px;
}