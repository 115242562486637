.row-is-arrangeable {
    background-color: #f7effb;
    display: flex !important;
    justify-content: space-around;
    align-items: center;
    border: unset;
}

.row-is-arrangeable td {
    border: unset;
}

table.ErjTable {
    overflow: visible;
}

.ErjTable tr.clickable {
    cursor: pointer;
}

.ErjTable tr.clickable td:first-child {
    position: relative;
}

.ErjTable tr.clickable td:first-child:before {
    content: ' ';
    background: url("../../assets/arrow_down.png") no-repeat center center;
    height: 18px;
    width: 18px;
    display: block;
    position: absolute;
}

.ErjTable tr.clickable.sub-row-open td:first-child:before {
    transform: rotate(180deg);
}

.ErjTable tr.clickable.sub-row-close td:first-child:before {
    transform: unset;
}

.ErjTable .tableCell {
    text-align: center;
    font-family: "OpenSans", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 13px;
    color: #868d9b;
    border-top: 1px solid #e4e9ef;
    padding: 8px 12px;
}

.ErjTable .headTableRow {
    height: 44px;
}

.ErjTable .headTableRow .tableCell {
    font-weight: bold;
    border-top: 0;
    color: #33363b;
    padding: 8px 8px 8px 24px;
}

.ErjTable .tableCell.order {
    text-align: center;
}

.ErjTable .tableCell.order .arrows {
    cursor: pointer;
}

.ErjTable .tableCell.order .arrows .arrow-up {
    height: 10px;
    background: url("../../assets/arrow_up.png") no-repeat center;
}

.ErjTable .tableCell.order .arrows .arrow-down {
    height: 10px;
    background: url("../../assets/arrow_down.png") no-repeat center;
}

.ErjTable .headTableRow .tableCell svg {
    display: none;
}

.ErjTable .headTableRow .tableCell .sortLabel:after {
    margin-left: 4px;
    user-select: none;
    margin-right: 4px;
    display: inline-block;
    width: 8px;
    height: 7px;
    content: '';
}

.ErjTable .headTableRow .tableCell[aria-sort="descending"] .sortLabel:after {
    background: url("../../assets/arrow_down.png") no-repeat;
}

.ErjTable .headTableRow .tableCell[aria-sort="ascending"] .sortLabel:after {
    background: url("../../assets/arrow_up.png") no-repeat;
}

.ErjTable .filter-button .erjMenuWrapper {
    width: 100%;
}

.ErjTable th .dropdownIcon {
    position: relative;
    top: -1px;
}

.ErjTable .erjMenuWrapper {
    position: relative;
}

.ErjTable .erjMenu-button .selectedValue {
    margin-left: 5px;
}

.ErjTable .erjMenuPopper {
    min-width: 100%;
}

.ErjTable .filter-button ul {
    margin-top: 12px;
    padding-top: 0;
    padding-bottom: 0;
    box-shadow: -3px 3px 3px -2.4px rgba(0, 0, 0, 0.3);
    text-align: center;
    background-color: #FFFFFF;
    z-index: 1;
    list-style-type: none;
    white-space: nowrap;
    padding-left: 0;
    position: absolute;
    width: 100%;
}

.ErjTable .filter-button .date-picker {
    direction: ltr;
    background: none;
    border: 0;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}

.ErjTable .filter-button .date-picker .selectedValue {
    margin: 0 5px;
    font-size: 13px;
}

.ErjTable .filter-button .erj-calendar .bottomButtons {
    clear: both;
    display: flex;
}

.ErjTable .filter-button .erj-calendar .bottomButtons button {
    border-radius: 0;
    flex: 1;
}

.ErjTable tbody .cell-no-data {
    text-align: center;
}

.ErjTable .caption {
    font-size: 13px;
}