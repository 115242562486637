[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Enable Ligatures ================ */
    letter-spacing: 0;
    -webkit-font-feature-settings: "liga";
    -moz-font-feature-settings: "liga=1";
    -moz-font-feature-settings: "liga";
    -ms-font-feature-settings: "liga" 1;
    font-feature-settings: "liga";
    -webkit-font-variant-ligatures: discretionary-ligatures;
    font-variant-ligatures: discretionary-ligatures;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

input::-ms-clear {
    display: none;
    height: 0;
    width: 0;
}

::selection {
    background-color: #338FFF;
    color: white;
}

/* disable ripple animation across the app */
button > span > span > span, a > span > span > span > span, li > span > span > span {
    user-select: none;
    animation: mui-ripple-exit none, mui-ripple-enter none !important;
}

td {
    user-select: text;
}

html {
    height: 100%;
}

body.enerjoyApp {
    background: #f5f5f5;
    font-family: "OpenSans", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
    color: #33363b;
    height: 100%;
    overflow-y: scroll !important;
    padding: 0 !important;
}

body.enerjoyApp #root {
    height: 100%;
}

.enerjoyApp .container {
    max-width: 1240px;
    padding: 0;
}

.enerjoyApp.lang_he .localText, .enerjoyApp.lang_he .localPopperText {
    direction: rtl;
}

.enerjoyApp.lang_he .inlineLocalText {
    direction: rtl;
    display: inline-block;
}

.enerjoyApp.lang_he .localText.aligned, .enerjoyApp.lang_he .inlineLocalText.aligned {
    text-align: right;
}

.enerjoyApp.langDir_rtl .localBox {
    direction: rtl;
}

.enerjoyApp.langDir_rtl .localText.aligned {
    text-align: right;
}

.enerjoyApp input:-webkit-autofill, .enerjoyApp select:-webkit-autofill, .enerjoyApp textarea:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0px 9999px white;
}

.enerjoyApp button:focus {
    outline: unset;
}

.enerjoyApp .page {
    padding: 20px 63px;
}

.grecaptcha-badge {
    visibility: hidden;
}

.enerjoyApp .appError {
    background-color: #fff3cd;
    border-color: #ffeeba;
    color: #856404;
    margin: 1rem 0;
    padding: .75rem 1.25rem;
}

.enerjoyApp .form-control, .enerjoyApp .custom-select {
    border-radius: 0;
}

.enerjoyApp input,
.enerjoyApp select,
.enerjoyApp textarea {
    color: #999faa;
    height: 50px;
    padding-left: 25px;
    font-size: 14px;
}

.enerjoyApp select {
    background: url("../../assets/arrow_down.png") no-repeat right 15px center;
    background-color: white;
    padding-right: 35px;
}

.enerjoyApp .custom-select[multiple] {
    padding: 0;
}

.enerjoyApp .custom-select[multiple] option {
    padding: 0.375rem 1.75rem 0.375rem 25px;
}


.enerjoyApp [role="tooltip"]:not(.menu):not(.popOver) {
    opacity: 1;
}

.enerjoyApp [role="tooltip"]:not(.menu):not(.popOver) > div {
    text-align: center;
    line-height: 1em;
    font-size: 14px;
    opacity: 1;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    color: #2a1566;
    border: 0;
    background-color: #FFF;
    box-shadow: 0 0 5px 5px rgba(94, 63, 186, 0.08);
    border-radius: 5px;
}

.enerjoyApp .menu > div {
    border-radius: 0;
}

.enerjoyApp svg {
    vertical-align: baseline;
}

.enerjoyApp .btn {
    height: 39px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 14px;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
}

.enerjoyApp .btn:disabled {
    cursor: default;
}

.enerjoyApp .btn-white, .enerjoyApp .btn-white:hover {
    width: 100px;
    color: #000000;
    background-color: #FFFFFF;
    border: 1px solid #C4CBD4;
}

.enerjoyApp .btn-green, .enerjoyApp .btn-green:hover, .enerjoyApp .btn-green:disabled {
    min-width: 132px;
    color: #FFFFFF;
    background-color: #9824c6;
}

.enerjoyApp .btn-blue, .enerjoyApp .btn-blue:hover {
    min-width: 138px;
    color: #FFFFFF;
    background-color: #189ACB;
}

.enerjoyApp .btn-gray, .enerjoyApp .btn-gray:hover, .enerjoyApp .btn-gray:disabled {
    color: #33363b;
    background-color: #DDD;
    width: 102px;
}

.enerjoyApp .btn-cherry, .enerjoyApp .btn-cherry:hover {
    background-color: #D42858;
    color: #FFFFFF;
}

.enerjoyApp .btn-orange, .enerjoyApp .btn-orange:hover {
    background-color: #FEA142;
    color: #FFFFFF;
}

.enerjoyApp .invalid-feedback {
    display: block;
    position: relative;
    top: 21px;
    margin-top: -21px;
}

.enerjoyApp .invalid-feedback.pull-up {
    top: 0;
}

.enerjoyApp .is-invalid {
    border-color: #dc3545 !important;
}

.enerjoyApp, .defaultFont span {
    font-family: "OpenSans", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
}

.enerjoyApp .flex {
    flex: 1;
}

.enerjoyApp a, .enerjoyApp a:link, .enerjoyApp a:active, .enerjoyApp a:hover, .enerjoyApp a:visited {
    color: #9824c6;
}

.enerjoyApp .checkbox-container {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.enerjoyApp .checkbox-container input {
    position: absolute;
    display: none;
    cursor: pointer;
}

.enerjoyApp .checkbox-container .checkmark {
    height: 16px;
    width: 16px;
    border: 2px solid #999faa;
    border-radius: 2px;
    position: absolute;
    top: 4px;
    left: 0;
    background-color: #FFFFFF;
}

.enerjoyApp .checkbox-container input:disabled ~ .checkmark {
    background-color: #bdbdbd;
}

.enerjoyApp .checkbox-container:hover input ~ .checkmark,
.enerjoyApp .checkbox-container input:checked ~ .checkmark {
    border-color: #5CBCFF;
}

.enerjoyApp .checkbox-container .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.enerjoyApp .checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

.enerjoyApp .checkbox-container .checkmark:after {
    width: 100%;
    height: 100%;
    background: url("../../assets/check.png") no-repeat center;
}

.enerjoyApp .hrs {
    font-family: "OpenSans", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 0.5em;
    opacity: 0.75;
    margin-bottom: -3px;
}

.enerjoyApp .main {
    padding: 13px 0 43px 0;
}

.enerjoyApp .appContent {
    background: #FFF;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.05);
    padding: 0;
    max-width: 1240px;
}

.enerjoyApp .contentBox {
    background: #FFF;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.05);
}

.enerjoyApp .form-row {
    margin-right: -13px;
    margin-left: -13px;
}

.enerjoyApp .form-row > .col, .enerjoyApp .form-row > [class*="col-"] {
    padding-right: 13px;
    padding-left: 13px;
}

.enerjoyApp .snackbar.info {
    top: 0;
}

.enerjoyApp .snackbar [role="alert"] {
    background-color: #189aca;
}

.enerjoyApp .snackbar.info [role="alert"] {
    background-color: #703dc2;
    font-size: 18px;
    display: flex;
    max-width: 100%;
    min-width: 440px;
}

.enerjoyApp .snackbar.info .snackbarMessage {
    flex: 1 1;
}

.enerjoyApp .snackbar.info .snackbarAction {
    flex: 0 0 72px;
    justify-content: flex-end;
}

.enerjoyApp .snackbar.info .feedbackSuccessMsg {
    display: flex;
}

.enerjoyApp .snackbar.info .feedbackIcon {
    font-size: 42px;
    margin-right: 20px;
    color: #ffdd00;
}

.enerjoyApp .snackbar.info .feedbackIcon {
    font-size: 42px;
    margin-right: 20px;
    color: #ffdd00;
}

.enerjoyApp .snackbar.info .notificationRowIcon {
    width: auto;
    height: 75px;
    margin-right: 20px;
}

.enerjoyApp .snackbar.info .notificationRowIcon img {
    border-radius: 50%;
    width: auto;
    height: 75px;
}

.enerjoyApp .snackbar.info .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.enerjoyApp .snackbar.info .title {
    font-weight: bold;
}

.enerjoyApp .snackbar.success [role="alert"] {
    background-color: #75d798;
}

.enerjoyApp .snackbar.error [role="alert"] {
    background-color: #D42858;
}

.enerjoyApp .snackbar.notificationsError [role="alert"] {
    background-color: #D42858;
    font-weight: bold;
    font-size: 15px;
    padding-left: 4.75em;
}

.enerjoyApp .snackbar.notificationsError [role="alert"] .icon-.alert {
    width: auto;
    height: auto;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 34px;
}

.enerjoyApp .radioBtn svg {
    width: 20px;
    height: 20px;
    fill: #8a919e;
}

.enerjoyApp .backArrow {
    border: solid white;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 8px;
    margin-right: -10px;
    transform: rotate(135deg);
}

.appContent h1 {
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    text-transform: uppercase;
}

.enerjoyApp .transparent {
    opacity: 0;
}

.page_performance_manage-goals .appContent,
.page_performance .appContent {
    background: transparent;
    box-shadow: none;
}

.pageContainer.page_home .appContent {
    min-width: 1242px;
}

.pageContainer.page_home.mob .appContent {
    min-width: auto;
    max-width: none;
}

.page_store .appContent {
    background-color: transparent;
    box-shadow: none;
}

.page_performance .appContent,
.page_manage-store .appContent {
    box-shadow: none;
}

.page_manage-store .appContent {
    background-color: unset;
}

.tabs button, .tabs button:focus, .tabs button span .tabLabel {
    outline: none;
    font-family: "OpenSans", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 14px;
    font-weight: normal;
    text-transform: capitalize;
    opacity: 1;
}

.tabs button[aria-selected="true"] span .tabLabel {
    font-family: "OpenSans", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    color: #9824c6;
}

.tabs button span .tabLabel.hasAlert:before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    background: #e53838;
    border-radius: 50%;
    box-shadow: 0 0 0 0.1em rgba(229, 56, 56, 0.33);
    display: inline-block;
    position: relative;
    right: 5px;
}

.tabs .indicator {
    background-color: #9824c6;
    height: 5px;
    transition: all 0s;
}

.tabs .indicator-white {
    background-color: rgba(255, 255, 255, 0.7);
    height: 5px;
    transition: all 0s;
}

.tab-link {
    cursor: pointer;
}

.employee-profile-link {
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    cursor: pointer;
}

.erjScroll .track-vertical {
    position: absolute;
    height: calc(100% - 14px);
    top: 7px;
    right: 0;
    width: 28px !important;
    z-index: 4;
}
.erjScroll .track-vertical:after {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
    left: 14px;
    width: 1px;
    border-left: 1px solid #f1f1f1;
}

.erjScroll .thumb-vertical, .erjScroll .thumb-horizontal {
    position: relative;
    z-index: 5;
}

.erjScroll .thumb-vertical:after {
    content: '';
    display: block;
    position: relative;
    left: 11px;
    width: 6px;
    height: 100%;
    background: #3b277b;
    border-radius: 3px;
    transition: 0.4s;
}

.erjScroll .track-horizontal {
    position: absolute;
    width: calc(100% - 14px);
    right: 7px;
    bottom: 0;
    height: 28px !important;
    z-index: 4;
}

.erjScroll .track-horizontal:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 10px;
    height: 1px;
    border-top: 1px solid #f1f1f1;
}

.erjScroll .thumb-horizontal:after {
    content: '';
    display: block;
    position: relative;
    top: 15px;
    height: 6px;
    width: 100%;
    background: #3b277b;
    border-radius: 3px;
    transition: 0.4s;
}

.widget-title {
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 18px;
    color: #414b6b;
    padding: 20px 0 7px 0;
    text-transform: uppercase;
}

.manager-summary {
    padding: 0 20px;
}

.manager-summary .greeting {
    font-size: 16px;
    color: #6724c6;
    padding-top: 15px;
    text-align: center;
    line-height: 17px;
}

.user-summary-widget .moduleRow {
    padding: 7px 0;
    border-bottom: 1px solid #dde1e7;
}

.user-summary-widget .moduleRow:last-child {
    border-bottom: 0;
}

.user-summary-widget .moduleRow.commissionsModule {
    text-align: left;
    padding: 10px;
    font-size: 15px;
}

.user-summary-widget .moduleTitle {
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 16px;
    color: #2a1566;
}

.user-summary-widget .moduleTitle .value {
    color: #ae24c6;
}

.user-summary-widget .moduleRow.commissionsModule .value {
    font-size: 16px;
    font-family: inherit;
}

.user-summary-widget .moduleTitle .titleIcon {
    position: relative;
    top: 6px;
}

.user-summary-widget .moduleTitle svg {
    font-size: 1.3em;
    position: relative;
    top: 3px;
}

.user-summary-widget .moduleContent {
    padding: 5px 0;
}

.user-summary-widget .moduleContent .warningLink a, .manager-summary .moduleContent .warningLink a:link, .manager-summary .moduleContent .warningLink a:active, .manager-summary .moduleContent .warningLink a:visited, .manager-summary .moduleContent .warningLink a:hover {
    font-size: 15px;
    color: #E53838;
    font-weight: bold;
    text-align: left;
}

.user-summary-widget .moduleContent .warningLink.info a {
    color: #147e2e;
}

.user-summary-widget .moduleContent .valueRow {
    display: flex;
}

.user-summary-widget .moduleContent .label {
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    color: #2a1566;
    margin-right: 5px;
}

.user-summary-widget .moduleContent .value {
    color: #ae24c6;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
}

.user-summary-widget .orderStatsItem {
    font-size: 15px;
    padding-left: 5px;
}

.user-summary-widget .moduleRow {
    width: 100%;
}

.user-summary-widget .incentivesModule {
    display: flex;
    padding: 7px 0;
    justify-content: space-evenly;
}

.personal-summary-widget .incentivesModule {
    padding: 8px 0;
    width: 100%;
}

.user-summary-widget .incentivesModule .summaryBtn {
    display: flex;
    align-items: center;
    background: #FFF;
    border: 1px solid #DDD;
    border-radius: 30px;
    padding: 10px 18px;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, .05), 0 0 5px 0 rgba(0, 0, 0, .05) inset;
    width: 157px;
}

.user-summary-widget .incentivesModule .summaryBtn.disabled {
    cursor: default;
}

.user-summary-widget .incentivesModule .summaryBtn.commission {
    position: relative;
    min-width: 200px;
    width: auto;
    margin-bottom: 12px;
}

.user-summary-widget .incentivesModule .summaryBtn.commission .benefitPrediction {
    position: absolute;
    top: 100%;
    left: 0;
    white-space: nowrap;
    margin-top: 5px;
    font-size: 0.9em;
    width: 100%;
    text-align: center;
}

.user-summary-widget .incentivesModule .pointsIcon, .user-summary-widget .incentivesModule .coinsIcon, .user-summary-widget .incentivesModule .clapIcon {
    height: 32px;
    width: 32px;
    flex: 0 0 32px;
}

svg.clapIcon #hand1, svg.clapIcon #hand2 {
    fill: rgb(123, 39, 218);
}

svg.clapIcon #clap_sound {
    fill: rgb(56, 237, 150);
}

.user-summary-widget .incentivesModule .commission svg {
    fill: rgb(123, 39, 218);
    width: 1.3em;
    height: 1.3em;
}

.user-summary-widget .commissionsModule .recommendationsHeader {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    border-bottom: 1px solid #941fa8;
}

.user-summary-widget .commissionsModule .recommendation {
    border-bottom: 4px solid #FFF;
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, rgba(232, 193, 239, 0.3) 0%, transparent 100%);
    border-radius: 15px;
    margin-left: -5px;
}

.langDir_rtl .user-summary-widget .commissionsModule .recommendation {
    background: linear-gradient(270deg, rgba(232, 193, 239, 0.3) 0%, transparent 100%);
    margin-left: 0;
    margin-right: -5px;
}

.user-summary-widget .commissionsModule .recommendation .changes {
    flex: 69% 0;
    padding: 5px 8px 3px 5px;
    overflow: hidden;
}

.langDir_rtl .user-summary-widget .commissionsModule .recommendation .changes {
    padding: 5px 5px 3px 8px;
}

.user-summary-widget .commissionsModule .recommendation .change {
    display: flex;
    padding: 0 0 2px;
    overflow: hidden;
    position: relative;
    border-radius: 6px;
}

.user-summary-widget .commissionsModule .recommendation .change:after {
    content: '';
    background: rgba(0, 0, 0, 0.15);
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 100%;
}

.user-summary-widget .commissionsModule .recommendation .change .progress {
    background: #ae24c6;
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 52%;
}

.langDir_rtl .user-summary-widget .commissionsModule .recommendation .change .progress {
    left: auto;
    right: 0;
}

.user-summary-widget .commissionsModule .recommendation .changeSeparator {
    height: 5px;
}

.user-summary-widget .commissionsModule .recommendation .metricName {
    flex: 61% 0;
    padding: 0 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.user-summary-widget .commissionsModule .recommendation .targetIcon {
    color: #4dd57c;
    flex: 12% 0;
}

.user-summary-widget .commissionsModule .recommendation .targetAmount {
    flex: 27% 0;
}

.user-summary-widget .commissionsModule .recommendation .outcomeIcon {
    flex: 10% 0;
    color: rgb(56 160 93 / 0.7);
    filter: drop-shadow(-6px 0px 1px rgb(56 160 93 / 0.5));
    transform: scaleX(1.5);
    padding-left: 9px;
    display: inline-flex;
}

.langDir_rtl .user-summary-widget .commissionsModule .recommendation svg {
    transform: rotateY(180deg);
}

.user-summary-widget .commissionsModule .recommendation .outcomeCommission {
    flex: 21% 0;
    text-align: right;
    font-weight: bold;
}

.langDir_rtl .user-summary-widget .commissionsModule .recommendation .outcomeCommission {
    text-align: left;
}

.checkInIcon {
    position: relative;
    width: 32px;
    height: 32px;
    font-size: 16px;
}

.checkInIcon:before {
    content: '';
    width: 2em;
    height: 2em;
    border-radius: 50%;
    border: 2px solid #7b27da;
    position: absolute;
    top: 0;
    left: 0;
}

.checkInIcon .checkMarkIcon {
    position: relative;
    z-index: 2;
    color: #38ed96;
    font-size: 1.8em;
    text-shadow: 0 0 1px #000, 0 0 1px #000, 0 2px 1px hsla(151, 83%, 25%, 1);
    top: -1px;
}

.user-summary-widget .incentivesModule .benefitContent {
    margin-left: 12px;
    flex: 1 1;
}

.user-summary-widget .incentivesModule .benefitLabel {
    font-weight: bold;
    color: #102F86;
    font-size: 10px;
    text-transform: uppercase;
}

.user-summary-widget .incentivesModule .benefitValue {
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    color: #102F86;
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 2px;
}

.manager-summary .employeeFeedbackStats {
    display: flex;
    margin-top: 5px;
}

.manager-summary .employeeFeedbackStats .employeesCol {
    flex: 50% 1 0;
    padding: 0 5px;
    text-align: center;
}

.manager-summary .employeeFeedbackStats .employeesCol:nth-child(2) {
    border-left: 1px solid #e7eaee;
}

.manager-summary .employeeFeedbackStats .colTitle {
    padding-bottom: 7px;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    color: #2a1566;
}

.manager-summary .employeeFeedbackStats .employees {
    display: flex;
    justify-content: space-evenly;
}

.manager-summary .employeeFeedbackStats .employee {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.manager-summary .employeeFeedbackStats .employee img, .manager-summary .warningEmployeesRows .employeeRow img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
}

.manager-summary .warningEmployeesRows {
    max-height: 400px;
    overflow: auto;
    padding-right: 10px;
    margin-right: -10px;
}

.manager-summary .warningEmployeesRows .employeeRow {
    padding: 5px 0;
}

.manager-summary .warningEmployeesRows .employeeRow img {
    margin-right: 10px;
}

.personal-summary-widget {
    padding: 20px 10px;
    text-align: center;
    position: relative;
}

.mob .personal-summary-widget {
    width: 100%;
}

.personal-summary-widget:not(.manager-summary-widget) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.personal-summary-widget .greeting {
    font-size: 16px;
    color: #6724c6;
    padding-top: 9px;
    padding-bottom: 15px;
}

.user-summary-widget .personalImg {
    display: block;
    margin: 20px auto 10px;
    width: 102px;
    height: 102px;
    border-radius: 50%;
}

.personal-summary-widget .itemsWaitingInStoreLink, .personal-summary-widget .itemsWaitingInStoreLink:link, .personal-summary-widget .itemsWaitingInStoreLink:active, .personal-summary-widget .itemsWaitingInStoreLink:hover, .personal-summary-widget .itemsWaitingInStoreLink:visited {
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    color: #FFFFFF;
    font-size: 13px;
    height: 37px;
    width: 75%;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px auto;
    text-decoration: none;
    background-color: #27DA85;
}

.personal-summary-widget .cartIcon {
    font-size: 25px;
    color: #FFFFFF;
    margin-right: 10px;
}

.manager-summary-widget {
    padding: 21px 85px;
}

.manager-summary-widget .managerTitle {
    font-size: 16px;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    padding-top: 13px;
}

.manager-summary-widget .managerPerformance {
    width: 285px;
    padding-top: 95px;
    margin: 0 auto;
}

.manager-summary-widget .managerPerformance .progressBar {
    width: 100%;
    position: relative;
    height: 20px;
    border-radius: 25px;
    border: 4px solid #F2F2F2;
    background: #FFFFFF;
}

.manager-summary-widget .managerPerformance .currentProgress {
    position: absolute;
    height: 100%;
    border-radius: 25px;
    background: #27DA85;
    top: 0;
    left: 0;
}

.manager-summary-widget .managerPerformance .progressBar .smileyCol {
    position: absolute;
    width: 66px;
    top: -44px;
    margin-left: -33px;
    text-align: center;
}

.manager-summary-widget .managerPerformance .progressBar .smileyCol.bad {
    left: 4%;
    margin-left: -27px;
}

.manager-summary-widget .managerPerformance .progressBar .smileyCol.ok {
    left: 50%;
}

.manager-summary-widget .managerPerformance .progressBar .smileyCol.good {
    left: 96%;
    margin-left: -39px;
}

.manager-summary-widget .managerPerformance .progressBar .smileyIcon {
    position: relative;
    width: 31px;
    height: 31px;
    top: -6px;
    left: 0;
    color: #E2E2E2;
    overflow: hidden;
    font-size: 29px;
}

.manager-summary-widget .managerPerformance .progressBar .smileyIcon.active {
    color: #27DA85;
    background: #FFF;
    border-radius: 50%;
}

.manager-summary-widget .managerPerformance .progressBar .smileyCol.good:before {
    content: '';
    width: 42px;
    height: 42px;
    position: absolute;
    top: -13px;
    right: 12px;
    border: 1px solid rgba(39, 218, 133, 0.18);
    border-radius: 50%;
    background: #C6F5DF;
    box-shadow: 0 0 0 6px #E9FBF3;
}

.manager-summary-widget .managerPerformance .progressBar .smileyCol:after {
    content: '';
    display: block;
    position: absolute;
    top: 69px;
    background: #000000;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    left: 50%;
    margin-left: -3px;
}

.manager-summary-widget .managerPerformance .progressBar .activeSmileyIconBg {
    color: #bcf6e9;
    font-size: 41px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.manager-summary-widget .managerPerformance .progressBar .feedback {
    position: absolute;
    top: 77px;
    left: 0;
    text-align: center;
    width: 100%;
    color: #27DA85;
    font-weight: bold;
    font-size: 16px;
}

.metrics-summary-widget .advancedLink-container {
    position: absolute;
    bottom: 19px;
    right: 34px;
    color: #2a1566;
}

.user-summary-widget .advancedLink-container {
    text-align: right;
    margin-bottom: -7px;
}

.user-summary-widget .commissionsModule .advancedLink-container {
    padding-top: 5px;
    display: flex;
    justify-content: space-between;
}

.user-summary-widget .advancedLink-container .bold_arrow_right,
.metrics-summary-widget .advancedLink-container .bold_arrow_right {
    font-size: 11px;
    margin-left: 10px;
}

.user-summary-widget .advancedLink,
.metrics-summary-widget .advancedLink-container .advancedLink {
    font-size: 14px;
    white-space: nowrap;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    color: #2a1566;
    background: none;
    border: 0;
    padding: 0;
}

.langDir_ltr .user-summary-widget .commissionsModule .advancedLink:first-child:before, .langDir_rtl .user-summary-widget .commissionsModule .advancedLink:first-child:before {
    content: '\002039';
    margin: 0 2px;
    font-size: 1.5em;
}

.user-summary-widget .advancedLink svg {
    font-size: 1.5em;
    position: relative;
    top: 5px;
}

.user-summary-widget .commissionsModule .advancedLink span:hover {
    text-decoration: underline;
}

.metrics-summary-widget .tabs {
    height: 36px;
    min-height: auto;
}

.metrics-summary-widget .tabs.metrics-widget-groupby {
    box-shadow: 0 0 17px 1px rgba(94, 63, 186, 0.09);
    border-radius: 25px;
}

.metrics-summary-widget .tabs button {
    min-height: 36px;
    height: 36px;
}

.metrics-summary-widget .tabs button span .tabLabel {
    color: #2a1566;
    z-index: 1;
    font-size: 15px;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    position: relative;
}

.metrics-summary-widget .tabs button[aria-selected="true"] span .tabLabel {
    color: #FFFFFF;
}

.metrics-summary-widget .indicator {
    height: 36px;
    background-color: #6724C6;
    border-radius: 25px;
    top: 0;
    transition: all 0s;
}

.metrics-summary-widget {
    padding: 26px 47px 75px 50px;
    position: relative;
}

.metrics-summary-widget .tabs button, .metrics-summary-widget .tabs button:focus {
    min-width: 120px;
    z-index: 1;
}

.metrics-summary-widget .header-line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
}

.mob .metrics-summary-widget .header-line {
    width: 100%;
    margin-bottom: 8px;
}

.metrics-summary-widget .teamMetricsTitle {
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 18px;
    color: #2a1566;
    text-transform: uppercase;
}

.metrics-summary-widget .metrics {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
}

.metrics-summary-widget .metrics .prevButton, .metrics-summary-widget .metrics .nextButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
}

.metrics-summary-widget .metrics .prevButton {
    left: 0;
}

.metrics-summary-widget .metrics .nextButton {
    right: 0;
}

.metrics-summary-widget .metrics .prevButton svg, .metrics-summary-widget .metrics .nextButton svg {
    width: 50px;
    height: 50px;
}

.metrics-summary-widget .metrics .paginationDots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.metrics-summary-widget .metrics .paginationDots .dot {
    width: 32px;
    height: 32px;
    padding: 0 0 6px 0;
    margin: 0 5px;
    color: rgba(0, 0, 0, 0.27);
    font-size: 24px;
}

.metrics-summary-widget .metrics .paginationDots .dot.active {
    color: rgba(0, 0, 0, 0.54);
}

.metrics-summary-widget .metricCol {
    flex: 247px 0 0;
    overflow: hidden;
}

.metrics-summary-widget .metricCol:nth-child(n + 4) {
    margin-top: 15px;
}

.mob .metrics-summary-widget .metricCol, .mob .metrics-summary-widget .metricCol:nth-child(3n + 2), .mob .metrics-summary-widget .metricCol:nth-child(n + 4) {
    flex: auto;
    margin: 0 0 15px 0;
}

.metrics-summary-widget .metrics > .metricCol:nth-child(-n+3) .metricBox {
    background-color: #249BC9;
}

.metrics-summary-widget .metrics > .metricCol:nth-child(n+4) .metricBox {
    background-color: #4CD57C;
}

.mob .metrics-summary-widget .metrics > .metricCol:nth-child(n) .metricBox {
    background-color: #249BC9;
}

.mob .metrics-summary-widget .metrics > .metricCol:nth-child(2n) .metricBox {
    background-color: #4CD57C;
}

.metrics-summary-widget .metricTitle {
    font-size: 19px;
}

.metrics-summary-widget .metricPerformance {
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 29px;
    padding-top: 6px;
}

.metrics-summary-widget .metricPerformance .leads {
    font-size: 35px;
}

.metricPerformance .slash {
    font-family: "OpenSans", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 15px;
    padding: 0 5px;
}

.metrics-summary-widget .metricPerformance .goal {
    font-family: "OpenSans", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 15px;
}

.metrics-summary-widget .progressBarContainer {
    display: flex;
}

.metrics-summary-widget .progressBarContainer .progressBar {
    position: relative;
    background-color: rgba(0, 0, 0, 0.25);
    height: 14px;
    border-radius: 25px;
    margin-top: 13px;
    padding: 0 3px;
    flex: 173px 1;
}

.mob .metrics-summary-widget .progressBarContainer .progressBar {
    margin: 0;
    height: 12px;
}

.metrics-summary-widget .progressBarContainer .leadsIndicator .icon- {
    font-size: inherit;
    width: auto;
    height: auto;
}

.metrics-summary-widget .progressBarContainer .currentProgress {
    margin-top: 2px;
    height: 10px;
    background: #FFFFFF;
    border-radius: 25px;
}

.mob .metrics-summary-widget .progressBarContainer .currentProgress {
    margin: 0;
    position: relative;
    top: 2px;
    height: 8px;
}

.metrics-summary-widget .progressBarContainer .leadsIndicator {
    height: 26px;
    width: 26px;
    overflow: hidden;
    border-radius: 50%;
    color: #FFF;
    font-size: 15px;
    text-align: center;
    line-height: 20px;
    background: #E53838;
    border: 2px solid #FFFFFF;
    position: relative;
    top: 6px;
    margin-right: 10px;
}

.metrics-summary-widget .progressBarContainer .leadsIndicator.good {
    background: #27DA85;
    line-height: 26px;
    font-size: 14px;
}

.metrics-summary-widget .metaInfo {
    position: absolute;
    bottom: 8px;
    right: 7px;
    display: flex;
}

.mob .metrics-summary-widget .metaInfo {
    top: 8px;
    bottom: auto;
}

.metrics-summary-widget .myRank {
    background: rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 0 5px;
    line-height: 20px;
}

.metrics-summary-widget .myRankTooltip {
    min-width: 150px;
    display: inline-block;
}

.metrics-summary-widget .weightMetricHelp {
    background: rgba(0, 0, 0, 0.25);
    padding: 0 5px;
    line-height: 20px;
    margin-left: 5px;
    cursor: pointer;
    width: 20px;
    text-align: center;
    border-radius: 50%;
}

.metrics-summary-widget .weightMetricHelp a, .metrics-summary-widget .weightMetricHelp a:link, .metrics-summary-widget .weightMetricHelp a:active,
.metrics-summary-widget .weightMetricHelp a:hover, .metrics-summary-widget .weightMetricHelp a:visited {
    color: #FFF;
    text-decoration: none;
}

.enerjoyApp [role="tooltip"]:not(.menu):not(.popOver).weightMetricHelpContent > div, .weightMetricHelpPopBody > div {
    text-align: left;
    line-height: normal;
}

.weightMetricHelpPopOver {
    width: 250px;
}

.weightMetricHelpContent .weight_table, .weightMetricHelpPopBody .weight_table {
    display: table;
    font-size: 14px;
    font-family: OpenSans-Semibold, OpenSansHebrew, Roboto, Helvetica, Arial, sans-serif;
    color: #2a1566;
}

.weightMetricHelpContent .leadType_item, .weightMetricHelpPopBody .leadType_item {
    display: table-row;
}

.weightMetricHelpContent .leadType_item span, .weightMetricHelpPopBody .leadType_item span {
    display: table-cell;
    padding: 0 5px;
}

.weightMetricHelpContent .leadType_item > span, .weightMetricHelpPopBody .leadType_item > span {
    text-align: right;
}

.metrics-summary-widget .fromPaceText {
    font-family: "OpenSans", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 13px;
    text-align: center;
    padding-top: 11px;
    float: left;
}

.mob .metrics-summary-widget .fromPaceText {
    padding-top: 3px;
    float: none;
    text-align: left;
}

.metrics-summary-widget .lastTransaction {
    display: flex;
    padding: 7px 10px 0 15px;
}

.mob .metrics-summary-widget .lastTransaction {
    padding: 3px 10px 4px 10px;
}

.metrics-summary-widget.personal-metrics-widget .lastTransaction {
    padding-left: 10px;
}

.metrics-summary-widget .lastTransaction .details {
    line-height: 22px;
}

.metrics-summary-widget .lastTransaction .userImg {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 9px;
}

.metrics-summary-widget .lastTransaction .name {
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    color: #2a1566;
    font-size: 14px;
}

.metrics-summary-widget .lastTransaction .time_ago {
    color: #99abbc;
    font-size: 14px;
}

.plus-input-minus .button {
    width: 15px;
    text-align: center;
    border: 1px solid gray;
}

.plus-input-minus .button span {
    text-align: center;
    vertical-align: center;
    font-size: 14px;
}

.manager-shop-widget .tab-link {
    margin-right: 20px;
}

.ErjForm legend {
    color: #AAA;
    text-transform: capitalize;
    font-size: 1.3em;
    margin-bottom: .6rem;
    padding-top: 15px;
}

.ErjForm fieldset:first-child legend {
    padding-top: 0;
}

.moduleDisabled_page h1 {
    padding: 20px 0;
}

.moduleDisabled_page .body {
    text-align: center;
    padding: 30px 0 50px;
}

.moduleDisabled_page .btn-promotion:link, .moduleDisabled_page .btn-promotion:hover, .moduleDisabled_page .btn-promotion:active, .moduleDisabled_page .btn-promotion:visited {
    color: #FFF;
    font-weight: bold;
    font-size: 16px;
    height: auto;
    padding: 10px 30px;
}

.enerjoyApp .moduleDisabledPage .appContent {
    background: #FFF;
    box-shadow: 0 3px 7px 0 rgb(0 0 0 / 5%);
}

.floatingAddRecordWidget {
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 3;
}

.floatingAddRecordWidget .formButton {
    margin: 7px;
    position: relative;
    z-index: 1301;
}

.floatingAddRecordWidget.isSubmitting .formButton:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.25);
    border-radius: inherit;
}

.floatingAddRecordWidget .formButton.addIcon {
    background-color: #4cd57c;
    color: #FFF;
    transition: background-color 0.4s;
}

.floatingAddRecordWidget .addIcon svg {
    transition: all 0.4s;
}

.floatingAddRecordWidget .addIcon:hover, .floatingAddRecordWidget .saveIcon:hover {
    background-color: #2ec262;
}

.floatingAddRecordWidget .formButton.saveIcon {
    transform: scale(0);
    background-color: #4cd57c;
    color: #FFF;
    transition: all 0.4s;
}

.floatingAddRecordWidget.formOpen .formButton.addIcon {
    background-color: #DDD;
    color: #33363b;
}

.floatingAddRecordWidget.formOpen .addIcon svg {
    transform: rotateZ(-135deg);
}

.floatingAddRecordWidget.formOpen .addIcon:hover {
    background-color: #CCC;
}

.floatingAddRecordWidget.formOpen .saveIcon {
    transform: scale(1);
}

.floatingAddRecordWidget .formContainer {
    position: fixed;
    width: 300px;
    right: 15px;
    bottom: 90px;
    padding: 0 15px 35px 15px;
    background: #FFF;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    outline: none;
}

@keyframes formSubmitOverlay {
    0% {
        background-position: 50% 110%;
    }
    100% {
        background-position: 50% -70%;
    }
}

@keyframes formSubmitOverlayHorizontal {
    0% {
        background-position: 110% 50%;
    }
    100% {
        background-position: -70% 50%;
    }
}

.floatingAddRecordWidget.isSubmitting .formContainer:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.25) 50%, rgba(0, 0, 0, 0.05) 53%, rgba(0, 0, 0, 0.25) 80%);
    background-size: 300% 300%;
    animation: formSubmitOverlay 1s linear infinite;
}

.floatingAddRecordWidget .add-record-form .input-label {
    font-size: 16px;
    padding: 15px 0 5px 0;
    font-weight: bold;
    color: #2a1566;
}

.floatingAddRecordWidget .add-record-form .leadTypesSelector {
    display: flex;
    flex-wrap: wrap;
    outline: 1px solid rgba(204, 204, 204, 0.5);
}

.floatingAddRecordWidget .add-record-form .leadTypesSelector.error {
    box-shadow: 0 0 2px red;
}

.floatingAddRecordWidget .add-record-form .leadTypesSelector .leadTypeOption {
    flex-basis: 33%;
    flex-grow: 1;
    flex-shrink: 0;
    background: #FFF;
    box-shadow: 0 0 3px #CCC inset;
    border: 0;
    padding: 10px;
    color: #2a1566;
    font-size: 15px;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
}

.floatingAddRecordWidget .add-record-form .leadTypesSelector .leadTypeOption.active {
    color: #9824c6;
    background-color: #f0def7;
    box-shadow: 0 0 3px #9824c6 inset;
}

.floatingAddRecordWidget .add-record-form .fieldRow {
    display: flex;
}

.floatingAddRecordWidget .add-record-form .fieldRow .field {
    padding-right: 10px;
    width: 50%;
}

.floatingAddRecordWidget .add-record-form .fieldRow .erj-number-input {
    padding-right: 10px;
    width: 100%;
}

.floatingAddRecordWidget .add-record-form .fieldRow .field:last-child {
    padding-right: 0;
}

.floatingAddRecordWidget .add-record-form .erj-number-input input {
    width: 100%;
}

.floatingAddRecordWidget .add-record-form .erj-number-input input.error {
    border-color: #e47777;
}

.floatingAddRecordWidget .add-record-form .erj-number-input .arrows {
    right: 15px;
}

.homepage .activityCanceled {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(248, 247, 252, 0.9);
    z-index: 2;
    border-radius: 8px;
    font-size: 32px;
    font-weight: bold;
    color: hsla(252, 25%, 48%, 1);
    text-shadow: 0 0 25px hsla(252, 25%, 48%, 1);
}

.pageAlertContainer {
    display: flex;
    justify-content: center;
    position: relative;
    top: -13px;
}

.pageAlert {
    padding: 6px 60px;
    background: hsla(60, 85%, 92%, 1);
    display: flex;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    position: relative;
    min-width: 360px;
    justify-content: center;
    text-align: center;
    color: #FB2337;
}

.pageAlert .alertContent {
    padding: 0;
    font-weight: bold;
}

.pageAlert .closeBtn {
    position: absolute;
    font-size: 1rem;
    padding: 7px;
    top: 0;
    right: 0;
    opacity: 0.5;
}

.pageAlert .closeBtn svg {
    font-size: 20px;
}

.message-bar {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    z-index: 4;
    height: 100%;
    align-items: flex-start;
    transform: translateX(400px);
    transition: transform 0.4s;
}

.message-bar.active {
    transform: translateX(0);
}

.message-bar .barIcons {
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    position: relative;
    top: 100px;
}

.message-bar .barIcon, .message-bar .barIcon:hover {
    width: 54px;
    height: 54px;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0;
    background: hsla(39, 100%, 70%, 1);
}

.message-bar .barIcon .numUnread {
    position: absolute;
    top: 5px;
    right: 5px;
    min-width: 18px;
    height: 18px;
    background: #e53838;
    border-radius: 9px;
    color: #FFF;
    font-family: "OpenSans", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 11px;
    text-align: center;
    line-height: 17px;
    animation: unread-pulse 1.2s infinite linear;
    padding: 0 5px;
}

.message-bar .barIcon.active:after {
    content: '';
    position: absolute;
    background: #FFF;
    top: 0;
    right: -4px;
    width: 5px;
    height: 100%;
}

.message-bar .barIcon svg path {
    fill: #FFF;
}

.message-bar .barIcon.notificationIcon svg {
    width: 80%;
    height: auto;
}

.message-bar .barIcon .closeIcon {
    width: 65%;
    height: auto;
}

.message-bar .messages {
    width: 400px;
    background: #F4F4F4;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.15), 0 3px 7px 0 rgba(0, 0, 0, 0.05) inset;
    height: 100%;
    border: 5px solid #FFF;
}

.message-bar.active .messages {
    overflow-y: auto;
}

.message-bar .messages .post {
    background: #FFF;
    margin: 10px 10px 8px 10px;
    padding: 10px;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.05);
    font-size: 18px;
    line-height: normal;
    text-align: center;
    position: relative;
}

.message-bar .messages .fileDropZone {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background: rgba(255, 255, 255, 0.75);
    color: #868ca0;
    font-weight: bold;
    border: 5px dashed;
}

.message-bar .messages .post textarea {
    font-size: inherit;
    color: inherit;
    line-height: inherit;
    text-align: inherit;
    background: transparent;
    border: 0;
    outline: 0;
    width: 100%;
    padding: 0;
    margin: 0;
}

.message-bar .messages .post textarea::placeholder {
    opacity: 0.6;
}

.message-bar .messages .post .messageText {
    min-height: 50px;
}

.message-bar .announcementContainer {
    position: relative;
    padding-bottom: 15px;
}

.message-bar .announcementContainer.futureAnnouncement .timeAgo {
    color: hsla(0, 70%, 50%, 1);
}

.message-bar .announcementContainer .savingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(244, 244, 244, 0.5);
    width: 100%;
    height: 100%;
    z-index: 2;
}

.message-bar .announcementContainer .publish-date-range {
    border: 0;
    background: none;
    padding-top: 5px;
    margin-left: 2px;
}

.message-bar .announcementContainer .publish-date-range .calendarIcon {
    font-size: 24px;
    margin-right: 3px;
    position: relative;
    top: 4px;
}

.message-bar .announcementContainer .editActionsArea {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 5px 0 25px;
    margin: 0 10px;
    border-bottom: 1px solid #BBB;
}

.message-bar .announcementContainer .editActions button {
    background: #FFF;
    border-radius: 7px;
    padding: 3px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
}

.message-bar .announcementContainer .editActionsArea .buttons button {
    text-transform: uppercase;
    font-size: 14px;
    font-family: "OpenSans-Semibold", "OpenSansHebrew", "Roboto", "Helvetica", "Arial", sans-serif;
    min-width: 100px;
    margin-left: 10px;
    padding: 4px 8px;
}

.message-bar .announcementContainer .editActionsArea .buttons button[disabled] {
    opacity: 0.5;
}

.message-bar .announcementContainer .editActionsArea .buttons .saveButton {
    color: #FFFFFF;
    background-color: #9824c6;
}

.message-bar .announcementContainer .editActionsArea .buttons .cancelButton {
    color: #33363b;
    background-color: #DDD;
    width: 102px;
}

.message-bar .announcementContainer .editActionsArea .publicInfo {
    flex: 100% 0 0;
    position: relative;
    margin-bottom: -10px;
}

.message-bar .announcementContainer .editActionsArea .publicInfo .icon- {
    font-size: 2em;
    position: relative;
    top: 0.2em;
    margin: 0 -0.2em;
}

.message-bar .announcementContainer .metaContent {
    margin: 0 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #CCC;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}

.message-bar .announcementContainer .metaContent > div {
    display: flex;
    align-items: center;
}

.message-bar .announcementContainer .metaContent .createdBy .userImg {
    width: 20px;
    height: auto;
    border-radius: 50%;
    margin-right: 5px;
}

.message-bar .announcementContainer .metaContent .timeAgoIcon {
    width: 20px;
    height: auto;
    margin-right: 5px;
}

.message-bar .messages .richItem {
    background: #f4f4f4;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15) inset;
    font-size: 0;
    position: relative;
}

.message-bar .messages .richItem iframe {
    width: 350px;
    height: 197px;
}

.message-bar .messages .removeRichItemButton {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0;
    background: rgba(255, 255, 255, 0.5);
}

.message-bar .messages .removeRichItemButton:hover {
    background: rgba(255, 255, 255, 0.75);
}

.message-bar .messages .removeRichItemButton .closeIcon {
    width: 16px;
    height: 16px;
}

.message-bar .messages .removeRichItemButton .closeIcon path {
    fill: #2a1566;
}

.message-bar .messages .richItemContent {
    text-align: left;
    font-size: 16px;
    padding: 10px;
    color: inherit;
    display: block;
}

.message-bar .messages .richItemContent:hover {
    text-decoration: none;
}

.message-bar .messages .richItemImage {
    display: block;
}

.message-bar .messages .richItemImage img {
    width: 100%;
    height: auto;
}

.message-bar .messages .richItem .titleArea {
    display: flex;
    align-items: center;
    font-weight: bold;
    overflow-wrap: break-word;
}

.message-bar .messages .richItem .title {
    overflow: hidden;
    flex: 1;
}

.message-bar .messages .richItem .favIcon {
    margin-right: 10px;
    min-height: 16px;
    width: auto;
    max-height: 32px;
}

.message-bar .messages .richItem .fileIcon {
    margin-right: 10px;
    height: auto;
    width: 100%;
    flex: 36px 0 0;
}

.message-bar .messages .richItem .description {
    margin-top: 5px;
    overflow-wrap: break-word;
    font-size: 15px;
}

.message-bar .messages .richItem .mainImage {
    width: 100%;
    height: auto;
    margin-top: 10px;
}

.message-bar .noNewAnnouncements {
    text-align: center;
    padding: 30px;
    font-size: 26px;
    color: rgba(66, 76, 108, 0.62);
    font-weight: bold;
}
.message-bar .noNewAnnouncements svg {
    width: 45%;
    height: auto;
    margin-bottom: 10px;
    fill: rgba(66, 76, 108, 0.62);
}

.message-bar .noNewAnnouncements .announcementsExplanationText {
    font-size: 18px;
    margin-top: 20px;
    font-weight: normal;
    color: rgba(66, 76, 108, 1);
}

.message-bar .post .hoverActions {
    display: none;
}

.message-bar .post:hover .hoverActions {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.33);
    z-index: 3;
}

.message-bar .post .hoverActions button {
    background: rgba(255, 255, 255, 0.75);
    margin: 0 10px;
}

.message-bar .post .hoverActions button:hover {
    background: #FFF;
}

.message-bar .create-new-item {
    display: flex;
    align-items: center;
    color: #9824c6;
    cursor: pointer;
    background-color: unset;
    border: none;
    text-align: left;
    padding: 15px 0;
    margin-left: 75px;
}

.message-bar .create-new-item {
    outline: unset;
}

.message-bar .create-new-item .plus_in_circle {
    font-size: 32px;
    margin-right: 10px;
    transition: 1s all;
}

@keyframes erj-pulse {
    43% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    57% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes unread-pulse {
    0% {
        box-shadow: 0 0 0 2px rgba(229, 56, 56, 0.33), 0 0 0 0px rgba(229, 56, 56, 0), 0 0 0 0px rgba(229, 56, 56, 0);
    }

    50% {
        box-shadow: 0 0 0 2px rgba(229, 56, 56, 0.33), 0 0 0 4px rgba(229, 56, 56, 0.22), 0 0 0 6px rgba(229, 56, 56, 0.11);
    }

    100% {
        box-shadow: 0 0 0 2px rgba(229, 56, 56, 0.33), 0 0 0 8px rgba(229, 56, 56, 0), 0 0 0 12px rgba(229, 56, 56, 0);
    }
}

@keyframes bounceInOutLeftToRight {
    0%,
    8%,
    12%,
    14%,
    35% {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        -webkit-transform: translate3d(-3000px, 0, 0);
        transform: translate3d(-3000px, 0, 0);
    }

    8% {
        opacity: 1;
        -webkit-transform: translate3d(25px, 0, 0);
        transform: translate3d(25px, 0, 0);
    }

    10% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }

    12% {
        -webkit-transform: translate3d(5px, 0, 0);
        transform: translate3d(5px, 0, 0);
    }

    14% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    35% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    40% {
        opacity: 1;
        -webkit-transform: translate3d(-20px, 0, 0);
        transform: translate3d(-20px, 0, 0);
    }

    60% {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translate3d(2000px, 0, 0);
        transform: translate3d(2000px, 0, 0);
    }
}

@keyframes fadeInOutDownToUp {
    0% {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
    30% {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }

    40% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    80% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    90% {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }

    100% {
        opacity: 0;
        transform: translate3d(0, -100%, 0);
    }
}