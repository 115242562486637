.loadingOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(245, 245, 245, 1);
    z-index: 1050;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loadingOverlay .progressCircle {
    color: #9824c6;
    opacity: 0.75;
}

.enerjoyApp .pageLoadProgressContainer {
    padding: 50px 0;
}

.enerjoyApp .pageLoadProgressCircle {
    color: #9824c6;
    opacity: 0.75;
    display: block;
    margin: 50px auto;
    padding: 0;
}